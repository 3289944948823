import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import { isDateBetween, postColumns } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import { usePostQuery } from "../../queries/posts";
import { useCategoryQuery } from "../../queries/categories";
import { useUserQuery } from "../../queries/users";

export default function Posts() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [cats, setCats] = useState([]);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = usePostQuery();
  const { data: catData, isLoading: catLoading } = useCategoryQuery();
  const { data: userData, isLoading: userLoading } = useUserQuery();

  useEffect(() => {
    if (catData && !catLoading && catData.items) {
      setCats(catData.items);
    }
  }, [catLoading, catData]);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsers(userData.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r, index) => {
        return {
          id: index + 1,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          category: r?.category?.name,
          user:
            r?.user?.name + " " + (r?.user?.lastName ? r?.user?.lastName : ""),
          title: r?.title,
          type: r?.type,
          tags: r?.tags,
          website: r?.website,
          status: r?.status && r.status ? "yes" : "no",
          user_id: r?.user_id,
          category_id: r?.category_id,
          action: (
            <>
              {/* <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/posts/edit/" + id);
                  }
                }}
              /> */}
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.title.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (parseInt(values.userId?.value) > -1) {
        options = options.filter((s) => {
          return s.user_id === parseInt(values.userId?.value);
        });
      }

      if (parseInt(values.catId?.value) > -1) {
        options = options.filter((s) => {
          return s.category_id === parseInt(values.catId?.value);
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        onAdd={() => navigate(paths.newPostPath)}
        onFilter={onSearch}
        onClear={onClear}
        type="posts"
        users={users}
        cats={cats}
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          "Please wait..."
        ) : (
          <CustomTable columns={postColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
