import React, { useMemo } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { useTable, usePagination } from "react-table";
import { paymentStatusOptions } from "./utils";

const CustomTable = ({
  columns,
  data,
  label = "",
  onRowClick = () => {},
  legend = false,
}) => {
  // Create a table instance with pagination functionality
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 }, // Initial page index and page size
    },
    usePagination
  );

  // Calculate page range to display
  const pageRange = 5; // Adjust this to change the number of pages displayed
  const pageCount = page.length;

  const showEllipsis = pageCount > pageRange;

  // Memoize the table instance to prevent unnecessary re-renders
  const table = useMemo(() => {
    return (
      <div className="table-wrapper table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="head" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={rowIndex % 2 === 0 ? "even" : "odd"}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }, [getTableProps, getTableBodyProps, headerGroups, page, prepareRow]);

  const getPageNumbers = () => {
    if (!showEllipsis) {
      return page.map((_, index) => index + 1);
    }

    const pageNumbers = [];
    const halfPageRange = Math.floor(pageRange / 2);
    const leftBound = Math.max(0, pageIndex - halfPageRange);
    const rightBound = Math.min(pageCount - 1, leftBound + pageRange - 1);

    if (leftBound > 0) {
      pageNumbers.push(1);
      if (leftBound > 1) {
        pageNumbers.push("...");
      }
    }

    for (let i = leftBound; i <= rightBound; i++) {
      pageNumbers.push(i + 1);
    }

    if (rightBound < pageCount - 1) {
      if (rightBound < pageCount - 2) {
        pageNumbers.push("...");
      }
      pageNumbers.push(pageCount);
    }

    return pageNumbers;
  };

  return (
    <div>
      {table}
      <div className="pagination">
        {legend ? (
          <div className="custom_legend">
            {paymentStatusOptions.map((m) => {
              return (
                <div className={"custom_legendItem " + m.color}>{m.name}</div>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}

        <div className="paginationLeft">
          <button onClick={previousPage} disabled={!canPreviousPage}>
            {"Previous"}
          </button>
          {/* <button onClick={nextPage} disabled={!canNextPage}>
          {">"}
  </button> 

          <span>
            {getPageNumbers().map((pageNum, index) => (
              <button
                key={index}
                onClick={() => {
                  if (typeof pageNum === "number") {
                    gotoPage(pageNum - 1);
                  }
                }}
                className={pageNum === pageIndex + 1 ? "active" : ""}
              >
                {pageNum}
              </button>
            ))}
          </span>*/}
          <button onClick={nextPage} disabled={!canNextPage}>
            {"Next"}
          </button>
        </div>
        <div className="paginationRight">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
