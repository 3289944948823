import React, { useEffect } from "react";

export default function DrawingMap({ zoom, loc, onDraw, coordinates }) {
  useEffect(() => {
    if (loc && loc.lat && loc.lng) {
      loadMap();
    }
  }, [loc]);

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");
    if (mapEle) {
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        disableDefaultUI: true,
      });
      map.setMapTypeId("satellite");
      map.setHeading(45);

      // adding drawing manager
      // eslint-disable-next-line no-undef
      var drawingManager = new google.maps.drawing.DrawingManager({
        // eslint-disable-next-line no-undef
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
      });

      drawingManager.setMap(map);
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        function (polygon) {
          // Get coordinates of the polygon
          let coordinates = polygon.getPath().getArray();

          let fixedCoordinates = polygon
            .getPath()
            .getArray()
            .map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          // Use coordinates for your calculations (length, width, etc.)
          console.log("Polygon Coordinates:");
          onDraw({
            dynamic: fixedCoordinates,
            fixed: fixedCoordinates,
          });
        }
      );

      // adding drawing manager
      if (coordinates) {
        // eslint-disable-next-line no-undef
        var polygonToShow = new google.maps.Polygon({
          paths: coordinates,
          editable: true,
          draggable: true,
          fillColor: "#3d2673",
          fillOpacity: 0.35,
          strokeColor: "#3d2673",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        polygonToShow.setMap(map);
      }
    }
  };

  return <div id="customMap"></div>;
}
