import React, { useState } from "react";
import {
  installerMenuList,
  menuList,
  operatorMenuList,
  retailerMenuList,
  userMenuList,
} from "../config/api";
import { useAuth } from "../authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { ADMINROLE, INSTALLERROLE, OPERATORROLE, RETAILERROLE } from "./utils";

export default function MenuInfo() {
  const [selected, setSelected] = useState("");
  const [menuStatus, setMenuStatus] = useState(false);
  const { userRoleName } = useAuth();

  const { authLogout } = useAuth();
  const navigate = useNavigate();
  const locationInfo = useLocation();

  const getRouteVal = () => {
    return locationInfo.pathname &&
      locationInfo.pathname.split("/") &&
      locationInfo.pathname.split("/")[1]
      ? locationInfo.pathname.split("/")[1]
      : "";
  };

  const getMenuList = () => {
    let val = [];
    switch (userRoleName) {
      case ADMINROLE:
        val = menuList;
        break;

      case RETAILERROLE:
        val = retailerMenuList;
        break;

      default:
        val = userMenuList;
        break;
    }

    return val;
  };

  return (
    <>
      <div className="wrapper_side_left" style={{ minWidth: "230px" }}>
        <div className="header">
          <img className="logo_info" src="/assets/logo.png" alt="logo" />
          <BsList
            onClick={() => {
              setMenuStatus(!menuStatus);
            }}
            className="menuBtn cursor"
          />
        </div>

        <div className={menuStatus ? "menuList active" : "menuList"}>
          {getMenuList() &&
            getMenuList().map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item.value === "logout") {
                      authLogout();
                    } else {
                      setSelected(item.value);
                      navigate(item.path);
                      setMenuStatus(false);
                    }
                  }}
                  className={
                    selected === item.value || getRouteVal() === item.routeVal
                      ? "menuListItem active"
                      : "menuListItem"
                  }
                >
                  {item.name}
                </div>
              );
            })}
        </div>
        <div className="copyRight">
          &copy;
          <a
            target="_blank"
            href="https://www.linkedin.com/in/saisoft00/"
            style={{ color: "#fff" }}
            rel="noreferrer"
          >
            Saida rao ipparla
          </a>
        </div>
      </div>
    </>
  );
}
