import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";

import { useUserQuery } from "../../queries/users";
import { useQuoteQuery } from "../../queries/quotes";
import { CUSTOMERROLE, OPERATORROLE, eventStatusList } from "../utils";
import { useAuth } from "../../authContext";
import Select from "react-select";
import CustomSelect from "./utils/CustomSelect";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import {
  useCreatePlanMutation,
  usePlanQuery,
  useUpdatePlanMutation,
} from "../../queries/plans";

const PlanForm = ({ onEdit = false, editId = null, onClose = () => {} }) => {
  const [submittable, setSubmittable] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [quotesData, setQuotesData] = useState([]);
  const [editData, setEditData] = useState();
  const { authUserId, userRoleName } = useAuth();

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = usePlanQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdatePlanMutation)
    : (mutationHook = useCreatePlanMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required("Nome is required"),
    description: Yup.string().required("description is required"),
    //startDate: Yup.string().required("start date is required"),
    //endDate: Yup.string().required("end date is required"),
    status: Yup.bool().required("status is required"),
    price: Yup.string().required("price  is required"),
    currency: Yup.string().required("currency is required"),
    limit: Yup.string().required("limit  is required"),
  };

  const editSchema = {
    description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.plansPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      description: values.description,
      status: values.status,
      limit: values.limit,
      price: values.price,
      currency: values.currency,
    };

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="EventForm">something went wrong try again later</div>
    );
  }

  return (
    <Container fluid className="EventForm" style={{ padding: "20px" }}>
      <button
        onClick={() => {
          navigate(paths.plansPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>

      <h4 className="my-2">{onEdit ? "Update" : "Create"} Plan</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              description: editData?.description ?? "",
              price: editData?.price ?? "",
              currency: editData?.currency ?? "",
              //startDate: editData?.startDate ?? "",
              //endDate: editData?.endDate ?? "",
              limit: editData?.limit ?? "",
              status: editData?.status && editData.status ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">Status</label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                    </div>
                  </Col>
                  {/* <Col sm={12} md={12}>
                    <CustomSelect
                      label="Status"
                      name="status"
                      defaultValue={values.status}
                      options={
                        eventStatusList &&
                        eventStatusList?.map((option, index) => ({
                          label: option.name,
                          value: option.value,
                        }))
                      }
                    />
                    </Col> */}
                </Row>

                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label="Name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.price}
                      name="price"
                      label="price"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.currency}
                      name="currency"
                      label="currency"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.limit}
                      name="limit"
                      label="limit"
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="start"
                      label="Start date"
                      type="datetime-local"
                      defaultValue={values.start}
                      placeholder="start date"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="end"
                      label="End Date"
                      type="datetime-local"
                      defaultValue={values.end}
                      placeholder="end date"
                    />
                  </Col>
                  </Row> */}

                <Row>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name="description"
                      label="Description"
                      defaultValue={values.description}
                      placeholder=""
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      cancel
                        </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default PlanForm;
