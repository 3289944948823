import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import CustomSelect from "./utils/CustomSelect";
import { useGroupQuery } from "../../queries/groups";
import { usePostQuery } from "../../queries/posts";
import { useCategoryQuery } from "../../queries/categories";
import CustomTextArea from "./utils/CustomTextArea";
import { useAuth } from "../../authContext";
import axios from "axios";
import { getFromLocalStorage } from "../../utils/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { usePostStatQuery } from "../../queries/postStats";

const PostForm = ({ onEdit = false, editId = null, rolesData = [] }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const queryClient = useQueryClient();

  const { authUserId } = useAuth();
  const [file, setFile] = useState(null);
  const [cats, setCats] = useState([]);
  const [groups, setGroups] = useState([]);
  const [stats, setStats] = useState({});

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = usePostQuery(
    editId,
    editId ? true : false
  );

  const { data: catData, isLoading: catLoading } = useCategoryQuery();
  const { data: groupData, isLoading: groupLoading } = useGroupQuery();

  const { data: statsData, isLoading: statsLoading } = usePostStatQuery(
    editId,
    editId ? true : false
  );

  const newUserSchema = {
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    lat: Yup.string().required("Latitude is required"),
    lng: Yup.string().required("Longitude is required"),
  };

  const formSchema = Yup.object().shape(newUserSchema);

  useEffect(() => {
    if (
      statsData &&
      !statsLoading &&
      statsData.likes &&
      statsData.shares &&
      statsData.views
    ) {
      setStats({
        likes: statsData.likes,
        shares: statsData.shares,
        views: statsData.views,
      });
    }
  }, [statsLoading, statsData]);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (groupData && !isLoading && groupData.items) {
      setGroups(groupData?.items);
    }
  }, [groupLoading, groupData]);

  useEffect(() => {
    if (catData && !catLoading && catData.items) {
      setCats(catData?.items);
    }
  }, [catLoading, catData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      title: values?.title,
      description: values?.description,
      category_id: values.category_id?.value,
      duration: values.duration?.value,
      type: values.type?.value,
      status: values.status,
      website: values.website,
      tags: values.tags,
      lat: values.lat,
      lng: values.lng,
      location: values.location,
      radius: 100,
      user_id: authUserId,
      source: "web",
      file: file,
    };

    let formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    // Append additional form fields
    Object.keys(finalData).forEach((key) => {
      formData.append(key, finalData[key]);
    });

    try {
      const response = await axios.post(
        onEdit
          ? apiUrl + "/api/post/fileUpload/" + editData.id
          : apiUrl + "/api/post/postCreate",
        finalData,
        {
          headers: {
            Authorization: getFromLocalStorage("userToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.item && response.data.item.id > 0) {
        toast.dismiss();
        onEdit ? toast.success("updated") : toast.success("created");
        queryClient.invalidateQueries("posts");
        navigate(paths.postsPath);
      }

      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
      setSubmitting(false);
    }

    /*

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: formData });
    } else {
      mutation.mutate(finalData);
    }*/
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.postsPath);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    console.log(file);
  };

  if (isLoading || catLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  const getInitialData = (id, type) => {
    let data = {};

    if (type === "category") {
      cats.forEach((q) => {
        if (q.id === id) {
          data = {
            value: q.id,
            label: q.name,
          };
        }
      });
    }

    if (type === "duration") {
      durationOptions().forEach((q) => {
        if (q.value === id) {
          data = {
            value: q.value,
            label: q.label,
          };
        }
      });
    }

    if (type === "type") {
      typeOptions().forEach((q) => {
        if (q.value === id) {
          data = {
            value: q.value,
            label: q.label,
          };
        }
      });
    }

    return data;
  };

  const durationOptions = () => {
    return [
      {
        label: "1 HOUR",
        value: 1,
      },
      {
        label: "2 HOUR",
        value: 2,
      },
      {
        label: "3 HOUR",
        value: 3,
      },
      {
        label: "4 HOUR",
        value: 4,
      },
      {
        label: "5 HOUR",
        value: 5,
      },
    ];
  };
  const typeOptions = () => {
    return [
      {
        label: "image",
        value: "image",
      },
      {
        label: "video",
        value: "video",
      },
    ];
  };

  const onUpdatePost = async (type) => {
    let data = {
      user_id: authUserId,
      post_id: editId,
    };
    let reqUrl = "";
    // eslint-disable-next-line default-case
    switch (type) {
      case "like":
        reqUrl = apiUrl + "/api/postStat/like";
        data["like"] = 1;
        break;
      case "view":
        reqUrl = apiUrl + "/api/postStat/view";
        data["view"] = 1;
        break;

      case "share":
        reqUrl = apiUrl + "/api/postStat/share";
        data["share"] = 1;
        break;
    }
    try {
      const response = await axios.post(reqUrl, data, {
        headers: {
          Authorization: getFromLocalStorage("userToken"),
          //"Content-Type": "multipart/form-data",
        },
      });

      if (
        response.data &&
        response.data.status &&
        response.data.status === "OK"
      ) {
        toast.dismiss();
        queryClient.invalidateQueries("postStats");
        toast.success("updated");
      }

      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
    }
  };

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.postsPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>
      <h4 className="my-2">{onEdit ? "Information of the " : "Create"} post</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              title: editData?.title ?? "",
              description: editData?.description ?? "",
              category_id: getInitialData(editData?.category_id, "category"),
              duration: getInitialData(editData?.duration, "duration"),
              type: getInitialData(editData?.type, "type"),
              status: editData?.status && editData.status ? true : false,
              website: editData?.website ?? "",
              tags: editData?.tags ?? "",
              lat: editData?.lat ?? "",
              lng: editData?.lng ?? "",
              location: editData?.location ?? "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ formik, values }) => (
              <Form>
                {/* onEdit && (
                  <Row>
                    <Col md={12}>
                      <span></span>
                      <div
                        onClick={() => onUpdatePost("like")}
                        className="btn btn-primary"
                      >
                        likes{" "}
                        {stats?.likes && stats.likes.length > 0
                          ? stats.likes.length
                          : 0}
                      </div>
                      <div
                        onClick={() => onUpdatePost("view")}
                        className="btn btn-secondary m-2"
                      >
                        views{" "}
                        {stats?.views && stats.views.length > 0
                          ? stats.views.length
                          : 0}
                      </div>
                      <div
                        onClick={() => onUpdatePost("share")}
                        className="btn btn-info"
                      >
                        shares{" "}
                        {stats?.shares && stats.shares.length > 0
                          ? stats.shares.length
                          : 0}
                      </div>
                    </Col>
                  </Row>
                        ) */}
                <Row className="mt-2">
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">Status</label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      defaultValue={values.title}
                      name="title"
                      label="Title"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name="description"
                      label="Description"
                      defaultValue={values.description}
                      placeholder=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      defaultValue={values.location}
                      name="location"
                      label="Location"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      defaultValue={values.lat}
                      name="lat"
                      label="Latitude"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      defaultValue={values.lng}
                      name="lng"
                      label="Longitude"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <CustomSelect
                      label="Type"
                      name="type"
                      defaultValue={values.type}
                      options={typeOptions()}
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomSelect
                      label="Category"
                      name="category_id"
                      defaultValue={values.category_id}
                      options={
                        cats &&
                        cats?.map((option, index) => ({
                          label: option.name,
                          value: option.id,
                        }))
                      }
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <CustomSelect
                      label="Duration"
                      name="duration"
                      defaultValue={values.duration}
                      options={durationOptions()}
                    />
                  </Col>
                </Row>

                <Row>
                  {editData?.type === "image" && (
                    <Col sm={12}>
                      <img
                        src={
                          editData?.file_name && editData.file_name
                            ? "http://127.0.0.1:8000/storage/uploads/" +
                              editData.file_name
                            : ""
                        }
                        alt=""
                        width={"50px"}
                        height={"50px"}
                      />
                    </Col>
                  )}

                  <Col sm={12} md={12}>
                    <label className="form-label">File</label>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      className="form-control my-2"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      defaultValue={values.website}
                      name="website"
                      label="website"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      defaultValue={values.tags}
                      name="tags"
                      label="tags"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    {/* <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>

                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      back
                      </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default PostForm;
