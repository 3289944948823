import React from "react";
import { useParams } from "react-router-dom";
import QuoteForm from "../forms/QuoteForm";

export default function EditQuote() {
  const { id } = useParams();

  return (
    <>
      <QuoteForm onEdit editId={id} />
    </>
  );
}
