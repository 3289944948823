import React, { useEffect, useState } from "react";

import { useRoleQuery } from "../../queries/roles";
import UserForm from "../forms/UserForm";

export default function NewUser() {
  const [roles, setRoles] = useState([]);
  const {
    data: roleData,
    isIdle: roleIdle,
    isLoading: roleLoading,
    isError: roleError,
  } = useRoleQuery();

  useEffect(() => {
    if (roleData && !roleLoading && roleData.roles) {
      setRoles(roleData.roles);
    }
  }, [roleLoading, roleData]);

  return (
    <>
      <UserForm rolesData={roles} />
    </>
  );
}
