import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import CustomInput from "./utils/CustomInput";

import {
  useCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from "../../queries/categories";
import CustomSelect from "./utils/CustomSelect";

const CategoryForm = ({
  onEdit = false,
  editId = null,
  onClose = () => {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useCategoryQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateCategoryMutation)
    : (mutationHook = useCreateCategoryMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required("Nome is required"),
    status: Yup.bool().required("status is required"),
  };

  const editSchema = {
    //description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.catogriesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      active: values.status,
      type: values.type.value,
    };

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="EventForm">something went wrong try again later</div>
    );
  }

  const categoryTypes = [
    {
      name: "post category",
      value: "general",
    },
    {
      name: "user category",
      value: "businessType",
    },
  ];

  const getInitialCatInfo = (type) => {
    let user = {};
    categoryTypes.forEach((u) => {
      if (u.value === type) {
        user = {
          value: u.id,
          label: u.name,
        };
      }
    });
    return user;
  };

  return (
    <Container fluid className="EventForm" style={{ padding: "20px" }}>
      <button
        onClick={() => {
          navigate(paths.catogriesPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>

      <h4 className="my-2">{onEdit ? "Update" : "Create"} Category</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              status: editData?.active && editData.active ? true : false,
              type: getInitialCatInfo(editData?.type),
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">Live</label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                    </div>
                  </Col>
                  {
                    <Col sm={12} md={12}>
                      <CustomSelect
                        label="Type"
                        name="type"
                        defaultValue={values.type}
                        options={
                          categoryTypes &&
                          categoryTypes?.map((option, index) => ({
                            label: option.name,
                            value: option.value,
                          }))
                        }
                      />
                    </Col>
                  }
                </Row>

                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label="Name"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      cancel
                        </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default CategoryForm;
