import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/

const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const usePostStatQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["postStats", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/getPostStats", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

export const usePostsByTypeQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["postStats", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/getPostsByType", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};
