import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(6).required("Password  is required"),
});

const Login = ({ onLogin }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async (values, { setSubmitting }) => {
    // let's call the api to save the request of user
    let finalValues = {
      email: values.email,
      password: values.password,
    };
    try {
      // Make Axios POST request to save data
      const response = await axios.post(
        apiUrl + "/api/users/login",
        finalValues
      );
      if (response.status === 200 && response.data && response.data.token) {
        localStorage.setItem("userToken", response.data.token);
        toast.success("now you can see leads", {
          position: "top-right",
        });
        onLogin(response.data.token);
      } else {
        console.log(response.status, response);
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Error saving data:", error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <h3>Login</h3>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email Address:
                </label>
                <Field
                  type="text"
                  id="email"
                  className="form-control"
                  name="email"
                />
                <ErrorMessage className="danger" name="email" component="div" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
                <Field
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                />
                <ErrorMessage
                  className="danger"
                  name="password"
                  component="div"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button type="submit" className="btn btn-secondary">
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

export default Login;
