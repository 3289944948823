import React from "react";
import GroupForm from "../forms/GroupForm";

export default function NewGroup() {
  return (
    <>
      <GroupForm />
    </>
  );
}
