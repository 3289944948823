import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import paths from "./config/paths";
import Leads from "./admin/Leads/Leads";
import PrivateRoute from "./PrivateRoute";
import UserLogin from "./admin/UserLogin";
import Quotes from "./admin/Quotes/Quotes";
import Dashboard from "./admin/Dashbord";
import NewLead from "./admin/Leads/NewLead";
import EditLead from "./admin/Leads/EditLead";
import EditQuote from "./admin/Quotes/EditQuote";
import NewQuote from "./admin/Quotes/NewQuote";

import Roles from "./admin/Roles/Roles";
import NewRole from "./admin/Roles/NewRole";
import EditRole from "./admin/Roles/EditRole";
import Requests from "./admin/Requests/Requests";
import EditRequest from "./admin/Requests/EditRequest";
import { useAuth } from "./authContext";
import { useEffect } from "react";
import Me from "./admin/Me";
import Calender from "./admin/Calender/Calender";
import {
  CUSTOMERROLE,
  INSTALLERROLE,
  OPERATORROLE,
  RETAILERROLE,
} from "./admin/utils";
import Plans from "./admin/Plans/Plans";
import NewPlan from "./admin/Plans/NewPlan";
import EditPlan from "./admin/Plans/EditPlan";
import Users from "./admin/Users/Users";
import NewUser from "./admin/Users/NewUser";
import EditUser from "./admin/Users/EditUser";
import NewGroup from "./admin/Groups/NewGroup";
import EditGroup from "./admin/Groups/EditGroup";
import Groups from "./admin/Groups/Groups";
import EditCategory from "./admin/Categories/EditCategory";
import NewCategory from "./admin/Categories/NewCategory";
import Categories from "./admin/Categories/Categories";
import Posts from "./admin/Posts/Posts";
import NewPost from "./admin/Posts/NewPost";
import EditPost from "./admin/Posts/EditPost";

export default function Router() {
  const { userRoleName } = useAuth();
  const locationInfo = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userRoleName === RETAILERROLE) {
      if (locationInfo.pathname.indexOf("posts") !== -1) {
        navigate(paths.postsPath);
      }
      if (locationInfo.pathname.indexOf("groups") !== -1) {
        navigate(paths.groupsPath);
      } else {
        navigate(paths.postsPath);
      }
    }

    if (userRoleName === CUSTOMERROLE) {
      if (locationInfo.pathname.indexOf("posts") === -1) {
        navigate(paths.postsPath);
      }
    }

    if (userRoleName === INSTALLERROLE) {
      if (locationInfo.pathname.indexOf("calender") === -1) {
        navigate(paths.calenderPath);
      }
    }
  }, [userRoleName]);

  return (
    <Routes>
      <Route path={paths.userLoginPath} exact element={<UserLogin />} />

      <Route
        path={paths.dashboardPath}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.leadsPath}
        element={
          <PrivateRoute>
            <Leads />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newLeadPath}
        element={
          <PrivateRoute>
            <NewLead />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editLeadPath}
        element={
          <PrivateRoute>
            <EditLead />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.quotesPath}
        element={
          <PrivateRoute>
            <Quotes />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newQuotePath}
        element={
          <PrivateRoute>
            <NewQuote />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editQuotePath}
        element={
          <PrivateRoute>
            <EditQuote />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.usersPath}
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newUserPath}
        element={
          <PrivateRoute>
            <NewUser />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editUserPath}
        element={
          <PrivateRoute>
            <EditUser />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.groupsPath}
        element={
          <PrivateRoute>
            <Groups />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newGroupPath}
        element={
          <PrivateRoute>
            <NewGroup />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editGroupPath}
        element={
          <PrivateRoute>
            <EditGroup />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.postsPath}
        element={
          <PrivateRoute>
            <Posts />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newPostPath}
        element={
          <PrivateRoute>
            <NewPost />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editPostPath}
        element={
          <PrivateRoute>
            <EditPost />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.catogriesPath}
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newCategoryPath}
        element={
          <PrivateRoute>
            <NewCategory />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editCategoryPath}
        element={
          <PrivateRoute>
            <EditCategory />
          </PrivateRoute>
        }
      />

      {/* <Route
        path={paths.rolesPath}
        element={
          <PrivateRoute>
            <Roles />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newRolePath}
        element={
          <PrivateRoute>
            <NewRole />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editRolePath}
        element={
          <PrivateRoute>
            <EditRole />
          </PrivateRoute>
        }
      />*/}

      <Route
        path={paths.plansPath}
        element={
          <PrivateRoute>
            <Plans />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.newPlanPath}
        element={
          <PrivateRoute>
            <NewPlan />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.editPlanPath}
        element={
          <PrivateRoute>
            <EditPlan />
          </PrivateRoute>
        }
      />

      <Route
        path={paths.mePath}
        element={
          <PrivateRoute>
            <Me />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
