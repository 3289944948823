import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Cellulare  is required"),
  street: Yup.string().required("Street and number is required"),
  cap: Yup.number().required("Cap is required"),
  country: Yup.string().required("country required"),
  city: Yup.string().required("city is required"),
  province: Yup.string().required("province is required"),
});

const CustomerInfo = ({
  zoom,
  loc,
  coordinates,
  slopeEdge,
  address,
  techInfo,
  apiUrl,
  userInfo,
  idParam,
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    // let's call the api to save the request of user
    let finalValues = {
      tilt: techInfo?.tilt,
      inclination: techInfo?.inclination,
      energy_price: techInfo?.energy_price,
      people_count: techInfo?.people_count,
      heat_pump: techInfo?.heat_pump,
      air_conditioners_pump: techInfo?.air_conditioners_pump,
      induction_hub: techInfo?.induction_hub,
      energy_increase: techInfo?.energy_increase,
      email: values.email,
      mobile: values.phone,
      address: values.street,
      cap: values.cap,
      country: values.country,
      city: values.city,
      province: values.province,
      status: 2,
      name: values.firstName,
      surname: values.lastName,
      electric_car: techInfo?.electric_car,
      col: techInfo?.col,
      f1: techInfo?.f1,
      f2: techInfo?.f2,
      f3: techInfo?.f3,
    };
    let upateId = idParam ? idParam : localStorage.getItem("userId");
    if (upateId > 0) {
      try {
        // Make Axios POST request to save data

        const response = await axios.put(
          apiUrl + "/api/cusQuote/" + upateId,
          finalValues
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          //localStorage.removeItem("userId");
          toast.success("your Request sent successfully", {
            position: "top-right",
          });
          /*if (!idParam) {
            window.location.reload();
          }*/
        } else {
          console.log(response.status, response);
          toast.error("something went wrong try again later", {
            position: "top-right",
          });
        }
        setSubmitting(false);
      } catch (error) {
        console.error("Error saving data:", error);
        setSubmitting(false);
      }
    }
    console.log(finalValues);
  };

  return (
    <Container className="custom-container">
      <h3>Contact information</h3>
      <Formik
        initialValues={{
          firstName: userInfo?.name ? userInfo?.name : "",
          lastName: userInfo?.surname ? userInfo?.surname : "",
          email: userInfo?.email ? userInfo?.email : "",
          phone: userInfo?.mobile ? userInfo?.mobile : "",
          street:
            address?.address ?? userInfo?.address ? userInfo?.address : "",
          cap: address?.zip ?? userInfo?.cap ? userInfo?.cap : "",
          country:
            address?.country ?? userInfo?.country ? userInfo?.country : "",
          city: address?.city ?? userInfo?.city ? userInfo?.city : "",
          province:
            address?.country_state_code ?? userInfo?.province
              ? userInfo?.province
              : "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label className="form-label" htmlFor="firstName">
                  First Name:
                </label>
                <Field
                  type="text"
                  id="firstName"
                  class="form-control"
                  name="firstName"
                />
                <ErrorMessage
                  className="danger"
                  name="firstName"
                  component="div"
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name:
                </label>
                <Field
                  type="text"
                  class="form-control"
                  id="lastName"
                  name="lastName"
                />
                <ErrorMessage
                  className="danger"
                  name="lastName"
                  component="div"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email Address:
                </label>
                <Field
                  type="text"
                  id="email"
                  class="form-control"
                  name="email"
                />
                <ErrorMessage className="danger" name="email" component="div" />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Cellulare:
                </label>
                <Field
                  type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                />
                <ErrorMessage className="danger" name="phone" component="div" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label className="form-label" htmlFor="street">
                  Street and number:
                </label>
                <Field
                  type="text"
                  id="street"
                  class="form-control"
                  name="street"
                />
                <ErrorMessage
                  className="danger"
                  name="street"
                  component="div"
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label htmlFor="cap" className="form-label">
                  CAP:
                </label>
                <Field type="text" class="form-control" id="cap" name="cap" />
                <ErrorMessage className="danger" name="cap" component="div" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label className="form-label" htmlFor="country">
                  country:
                </label>
                <Field
                  type="text"
                  id="country"
                  class="form-control"
                  name="country"
                />
                <ErrorMessage
                  className="danger"
                  name="country"
                  component="div"
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  city:
                </label>
                <Field type="text" class="form-control" id="city" name="city" />
                <ErrorMessage className="danger" name="city" component="div" />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-3">
                <label htmlFor="province" className="form-label">
                  province:
                </label>
                <Field
                  type="text"
                  class="form-control"
                  id="province"
                  name="province"
                />
                <ErrorMessage
                  className="danger"
                  name="province"
                  component="div"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

export default CustomerInfo;
