import React from "react";
import PostForm from "../forms/PostForm";

export default function NewPost() {
  return (
    <>
      <PostForm />
    </>
  );
}
