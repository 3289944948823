import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRoleQuery } from "../../queries/roles";
import UserForm from "../forms/UserForm";

export default function EditUser() {
  const [roles, setRoles] = useState([]);
  const { id } = useParams();
  const {
    data: roleData,
    isIdle: roleIdle,
    isLoading: roleLoading,
    isError: roleError,
  } = useRoleQuery();

  useEffect(() => {
    if (roleData && !roleLoading && roleData.roles) {
      setRoles(roleData.roles);
    }
  }, [roleLoading, roleData]);

  return (
    <>
      <UserForm rolesData={roles} onEdit editId={id} />
    </>
  );
}
