// AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import paths, { apiUrl } from "./config/paths";
import Loading from "./components/Loading";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userRoleName, setUserRoleName] = useState("");
  const [authUserId, setAuthUserId] = useState(null);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check for the token in local storage
    const token = localStorage.getItem("userToken");

    if (token) {
      // If the token exists, call your API to verify its status
      // This is a simplified example; replace the URL and headers with your actual API details
      fetch(apiUrl + "/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            setAuthenticated(false);
          }
        })
        .then((res) => {
          if (res && res.user && res.user.role_id >= 0) {
            setAuthenticated(true);
            setUserRoleName(res.user?.role?.name);
            setAuthUserId(res.user.id);
            if (location.pathname === "/login") {
              if (res.user.role_id === 0) {
                navigate(paths.dashboardPath);
              } else {
                navigate(paths.postsPath);
              }
            }
          } else {
            setAuthenticated(false);
          }
        })
        .catch((error) => {
          console.error("Error checking token status:", error);
          setAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const authLogin = (token) => {
    // Save the token to local storage
    localStorage.setItem("userToken", token);
    setAuthenticated(true);
  };

  const authLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem("userToken");
    setAuthenticated(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authUserId,
        loading,
        authLogin,
        authLogout,
        userRoleName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
