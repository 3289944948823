import paths from "./paths";

const apiUrlInternal = process.env.REACT_APP_API_URL;
export { apiUrlInternal as default };

export const menuList = [
  {
    name: "Dashboard",
    value: "dashboard",
    routeVal: "dashboard",
    path: paths.dashboardPath,
  },
  {
    name: "Posts",
    value: "posts",
    routeVal: "posts",
    path: paths.postsPath,
  },

  {
    name: "Users",
    value: "users",
    routeVal: "users",
    path: paths.usersPath,
  },

  {
    name: "Groups",
    value: "groups",
    routeVal: "groups",
    path: paths.groupsPath,
  },
  {
    name: "Plans",
    value: "plans",
    routeVal: "plans",
    path: paths.plansPath,
  },
  {
    name: "Categories",
    value: "categories",
    routeVal: "categories",
    path: paths.catogriesPath,
  },
  /* {
    name: "Roles",
    value: "roles",
    routeVal: "roles",
    path: paths.rolesPath,
  },
  
   {
    name: "Leads",
    value: "leads",
    routeVal: "leads",
    path: paths.leadsPath,
  },
  {
    name: "Preventivi",
    value: "quotes",
    routeVal: "preventivi",
    path: paths.quotesPath,
  },
  {
    name: "Calender",
    value: "calender",
    routeVal: "calender",
    path: paths.calenderPath,
  },
 
  */
  {
    name: "logout",
    value: "logout",
    path: "",
  },
];

export const retailerMenuList = [
  {
    name: "Posts",
    value: "posts",
    routeVal: "posts",
    path: paths.postsPath,
  },
  {
    name: "Groups",
    value: "groups",
    routeVal: "groups",
    path: paths.groupsPath,
  },
  {
    name: "logout",
    value: "logout",
    path: "",
  },
];

export const userMenuList = [
  {
    name: "Posts",
    value: "posts",
    routeVal: "posts",
    path: paths.postsPath,
  },
  /* {
    name: "my profile",
    value: "me",
    routeVal: "me",
    path: paths.mePath,
  }, */

  {
    name: "logout",
    value: "logout",
    path: "",
  },
];

export const installerMenuList = [
  {
    name: "Calender",
    value: "calender",
    routeVal: "calender",
    path: paths.calenderPath,
  },
  {
    name: "logout",
    value: "logout",
    path: "",
  },
];
