import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/

const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const useGroupQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["groups", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/group", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

/* mutations */

const createGroupMutator = (params) => {
  return axios
    .post(apiUrl + `/api/group`, params, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useCreateGroupMutation = (mutator = createGroupMutator) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("groups"),
  });
};

export const updateGroupMutation = (params) => {
  const { id, data } = params;
  return axios
    .put(apiUrl + `/api/plan/${id}`, data, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useUpdateGroupMutation = (mutator = updateGroupMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("groups"),
  });
};

export const deleteGroupMutation = (params) => {
  const { id } = params;
  return axios
    .delete(apiUrl + `/api/group/${id}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useDeleteGroupMutation = (mutator = deleteGroupMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("groups"),
  });
};
