import React, { useEffect } from "react";

export default function MapMarker({
  zoom,
  loc,
  onZoomChange,
  onCenterChanged,
  onMarkerDragged = () => {},
}) {
  useEffect(() => {
    loadMap();
  });

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");
    if (mapEle) {
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        disableDefaultUI: true,
      });

      map.setMapTypeId("satellite");
      map.setHeading(45);

      // eslint-disable-next-line no-undef
      let marker = new google.maps.Marker({
        position: myLatLng,
        map,
        title: "",
        draggable: true,
        customName: "myMarkerInfo",
      });

      // Event listener to save the zoom level and center when changed

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(map, "zoom_changed", function () {
        let currentZoom = map.getZoom();
        //onZoomChange(currentZoom.toString());
      });

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(map, "center_changed", function () {
        saveMapState(map);
      });

      function saveMapState(map) {
        let currentCenter = map.getCenter().toJSON();
        onCenterChanged(currentCenter);
      }

      // for move marker event
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(marker, "dragend", function (event) {
        let currentZoom = map.getZoom();
        onMarkerDragged({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          zoom: currentZoom.toString(),
        });
      });
    }
  };

  return <div id="customMap"></div>;
}
