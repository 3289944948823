import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../authContext";
import CustomInput from "./forms/utils/CustomInput";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(6).required("Password  is required"),
});

const LoginvalidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(6).required("Password  is required"),
});

const UserLogin = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [page, setPage] = useState("login");

  const { authLogin } = useAuth();

  const handleSubmit = async (values, { setSubmitting }) => {
    let finalValues = {};
    let reqUrl = "";
    // eslint-disable-next-line default-case
    switch (page) {
      case "login":
        reqUrl = apiUrl + "/api/login";
        finalValues["email"] = values.email;
        finalValues["password"] = values.password;
        break;

      case "signup":
        reqUrl = apiUrl + "/api/signup";
        finalValues["email"] = values.email;
        finalValues["password"] = values.password;
        finalValues["name"] = values.firstName;
        finalValues["lastName"] = values.lastName;
        break;

      case "forget":
        reqUrl = apiUrl + "/api/forget";
        finalValues["email"] = values.email;
        break;

      case "update":
        reqUrl = apiUrl + "/api/updateUserPwd";
        finalValues["email"] = values.email;
        finalValues["password"] = values.password;
        finalValues["token"] = values.code;
        break;
    }

    if (page) {
      try {
        const response = await axios.post(reqUrl, finalValues);
        if (
          page === "forget" &&
          response.status === 200 &&
          response.data &&
          response.data.item &&
          response.data.item.id > 0
        ) {
          toast.success("code sent to email");
          setPage("update");
        }

        if (
          page === "update" &&
          response.status === 200 &&
          response.data &&
          response.data.item &&
          response.data.item.id > 0
        ) {
          toast.success("updated");
          setPage("login");
        }

        if (
          page === "login" &&
          response.status === 200 &&
          response.data &&
          response.data.token
        ) {
          authLogin(response.data.token);
        }

        if (
          page === "signup" &&
          response.status === 201 &&
          response.data &&
          response.data.user &&
          response.data.user.id > 0 &&
          response.data.token
        ) {
          authLogin(response.data.token);
        }

        setSubmitting(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message, {
            position: "top-right",
          });
        }
        setSubmitting(false);
      }
    }
  };

  const handleClear = (formik) => {
    formik.resetForm();
  };

  return (
    <div className="loginContainer">
      <Container>
        <h3>{page}</h3>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            code: "",
          }}
          validationSchema={!page ? validationSchema : LoginvalidationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              {page === "signup" && (
                <Row>
                  <Col>
                    <CustomInput name="firstName" label="Name" />
                  </Col>
                  <Col>
                    <CustomInput name="lastName" label="lastName" />
                  </Col>
                </Row>
              )}

              {page === "update" && (
                <Row>
                  <Col>
                    <CustomInput name="code" label="code" type="text" />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <CustomInput name="email" label="email" type="email" />
                </Col>
              </Row>
              {page !== "forget" && (
                <Row>
                  <Col>
                    <CustomInput
                      name="password"
                      label="password"
                      type="password"
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <div
                    onClick={() => {
                      setPage("forget");
                    }}
                    className="infoBtn cursor"
                  >
                    {"forgot password ?"}
                  </div>

                  {page === "signup" ||
                    (page === "forget" && (
                      <div
                        onClick={() => {
                          setPage("login");
                        }}
                        className="infoBtn cursor"
                      >
                        {"login"}
                      </div>
                    ))}
                  {page === "login" && (
                    <div
                      onClick={() => {
                        setPage("signup");
                      }}
                      className="infoBtn cursor"
                    >
                      {"signup"}
                    </div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <button type="submit" className="btn btn-success me-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClear(formik)}
                    className="btn btn-secondary me-2"
                  >
                    clear
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default UserLogin;
