import React from "react";
import LeadForm from "../forms/LeadForm";

export default function NewLead() {
  return (
    <>
      <LeadForm />
    </>
  );
}
