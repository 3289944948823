import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/

const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const usePlanQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["plans", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/plan", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

/* mutations */

const createPlanMutator = (params) => {
  return axios
    .post(apiUrl + `/api/plan`, params, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useCreatePlanMutation = (mutator = createPlanMutator) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("plans"),
  });
};

export const updatePlanMutation = (params) => {
  const { id, data } = params;
  return axios
    .put(apiUrl + `/api/plan/${id}`, data, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useUpdatePlanMutation = (mutator = updatePlanMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("plans"),
  });
};

export const deletePlanMutation = (params) => {
  const { id } = params;
  return axios
    .delete(apiUrl + `/api/plan/${id}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => res);
};

export const useDeletePlanMutation = (mutator = deletePlanMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("plans"),
  });
};
