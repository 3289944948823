export const leadColumns = [
  { Header: "ID", accessor: "id" },
  { Header: "Data", accessor: "date" },
  { Header: "Nome e Cognome", accessor: "name" },
  { Header: "Indirizzo", accessor: "add" },
  { Header: "Comune", accessor: "comune" },
  { Header: "Cellulare", accessor: "phone" },
  { Header: "E-Mail", accessor: "email" },
  { Header: "Fonte", accessor: "type" },
  { Header: "", accessor: "action" },
];

export const planColumns = [
  //{ Header: "ID", accessor: "id" },
  { Header: "Name", accessor: "name" },
  { Header: "Price", accessor: "price" },
  //{ Header: "Start date", accessor: "sd" },
  //{ Header: "End date", accessor: "ed" },
  { Header: "Limit", accessor: "limit" },
  { Header: "Status", accessor: "status" },
  { Header: "", accessor: "action" },
];

export const groupColumns = [
  //{ Header: "ID", accessor: "id" },
  { Header: "Name", accessor: "name" },
  { Header: "Start date", accessor: "sd" },
  { Header: "End date", accessor: "ed" },
  { Header: "Status", accessor: "status" },
  { Header: "", accessor: "action" },
];

export const categoryColumns = [
  //{ Header: "ID", accessor: "id" },
  { Header: "Name", accessor: "name" },
  { Header: "Status", accessor: "status" },
  { Header: "Type", accessor: "type" },
  { Header: "", accessor: "action" },
];

export const postColumns = [
  { Header: "ID", accessor: "id" },
  { Header: "user", accessor: "user" },
  { Header: "category", accessor: "category" },
  { Header: "title", accessor: "title" },
  { Header: "type", accessor: "type" },
  { Header: "tags", accessor: "tags" },
  //{ Header: "website", accessor: "website" },
  { Header: "Status", accessor: "status" },
  { Header: "", accessor: "action" },
];

export const clientsColumns = [
  { Header: "Name and surname", accessor: "name" },
  { Header: "Email", accessor: "email" },
  { Header: "Role", accessor: "role" },
  { Header: "Plan", accessor: "plan" },
  { Header: "Status", accessor: "status" },
  { Header: "", accessor: "action" },
];

export const rolesColumns = [
  //{ Header: "ID", accessor: "id" },
  { Header: "Nome", accessor: "name" },
  //{ Header: "", accessor: "action" },
];

export const quoteColumns = [
  { Header: "ID", accessor: "id" },
  { Header: "Nome e Cognome", accessor: "name" },
  { Header: "Comune", accessor: "comune" },
  { Header: "Cellulare", accessor: "phone" },
  { Header: "E-Mail", accessor: "email" },
  { Header: "FTV", accessor: "ftv" },
  { Header: "BAT", accessor: "bat" },
  { Header: "COL", accessor: "col" },
  { Header: "Sconto", accessor: "discount" },
  { Header: "Prezzo finale", accessor: "price" },
  { Header: "GSE", accessor: "gse" },
  { Header: "Instal", accessor: "install" },
  { Header: "Pagamenti", accessor: "payment" },

  { Header: "", accessor: "action" },
];

export const isDateBetween = (startDate, endDate, checkDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const check = new Date(checkDate);

  return start <= check && check <= end;
};

export const abitiOptions = [
  {
    name: "1-2",
    value: "1-2",
  },
  {
    name: "3-4",
    value: "3-4",
  },
  {
    name: "5-6",
    value: "5-6",
  },
  {
    name: "7-8",
    value: "7-8",
  },
  {
    name: "Piu di 9",
    value: "9-n",
  },
];

export const infoOptions = [
  {
    name: "si",
    value: "si",
  },
  {
    name: "no",
    value: "no",
  },
  {
    name: "futuro",
    value: "futuro",
  },
];

export const airConditionerOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "piu di 3",
    value: "3-n",
  },
  {
    name: "futuro",
    value: "futuro",
  },
];

export const eventStatusList = [
  {
    name: "In attesa",
    value: 1,
  },
  {
    name: "Rimandato",
    value: 2,
  },
  {
    name: "Annullato",
    value: 3,
  },
  {
    name: "Completato",
    value: 4,
  },
];

export const paymentStatusOptions = [
  {
    name: "Non eseguito",
    value: 0,
    color: "yash_clr",
  },
  {
    name: "In attesa",
    value: 1,
    color: "orange_clr",
  },
  {
    name: "Parziale",
    value: 2,
    color: "orange_green_clr",
  },
  {
    name: "Eseguito",
    value: 3,
    color: "green_clr",
  },
];

export const CUSTOMERROLE = "customer";
export const RETAILERROLE = "Retailer";
export const OPERATORROLE = "operator";
export const ADMINROLE = "admin";
export const INSTALLERROLE = "installer";
