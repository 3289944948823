import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { toast } from "react-toastify";
import {
  useCreateLeadMutation,
  useLeadQuery,
  useUpdateLeadMutation,
} from "../../queries/leads";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";

const LeadForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useLeadQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateLeadMutation)
    : (mutationHook = useCreateLeadMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Nome is required"),
    surname: Yup.string().required("Cognome is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Cellulare  is required"),
    add: Yup.string().required("Street and number is required"),
    zip: Yup.number().required("Cap is required"),
    country: Yup.string().required("country is required"),
    city: Yup.string().required("city is required"),
    province: Yup.string().required("province is required"),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.lead);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.lead &&
        mutation.data.data.lead.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.leadsPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      mobile: values.mobile,
      address: values.add,
      city: values.city,
      zip: values.zip,
      country: values.country,
      prov: values.province,
      referal_type: values.referal_type,
    };

    toast.warning("please wait..");

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const fonteOptions = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.leadsPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <button
        onClick={() => {
          navigate(paths.leadsPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>
      <h4 className="my-2">{onEdit ? "Update" : "Create"} Lead</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              surname: editData?.surname ?? "",
              email: editData?.email ?? "",
              mobile: editData?.mobile ?? "",
              add: editData?.address ?? "",
              referal_type: editData?.referal_type ?? "",
              province: editData?.prov ?? "",
              city: editData?.city ?? "",
              country: editData?.country ?? "",
              zip: editData?.zip ?? "",
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Row>
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      First Name:
                    </label>

                    <Field
                      type="text"
                      id="name"
                      placeholder="Nome"
                      className="form-control"
                      name="name"
                    />
                    <ErrorMessage
                      className="danger"
                      name="name"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      SurName:
                    </label>

                    <Field
                      type="text"
                      id="surname"
                      placeholder="Cognome"
                      className="form-control"
                      name="surname"
                    />
                    <ErrorMessage
                      className="danger"
                      name="surname"
                      component="div"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      Email:
                    </label>

                    <Field
                      type="text"
                      id="email"
                      placeholder="Email"
                      className="form-control"
                      name="email"
                    />
                    <ErrorMessage
                      className="danger"
                      name="email"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <label className="form-label" htmlFor="firstName">
                      Cellulare:
                    </label>

                    <Field
                      type="text"
                      id="mobile"
                      placeholder="Cellulare"
                      className="form-control"
                      name="mobile"
                    />
                    <ErrorMessage
                      className="danger"
                      name="mobile"
                      component="div"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      Indirizzo:
                    </label>

                    <Field
                      type="text"
                      placeholder="indirizzo"
                      className="form-control"
                      id="add"
                      name="add"
                    />
                    <ErrorMessage
                      className="danger"
                      name="add"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      country:
                    </label>

                    <Field
                      type="text"
                      id="country"
                      className="form-control"
                      placeholder="country"
                      name="country"
                    />
                    <ErrorMessage
                      className="danger"
                      name="country"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      Cap:
                    </label>

                    <Field
                      type="text"
                      placeholder="Cap"
                      className="form-control"
                      id="zip"
                      name="zip"
                    />
                    <ErrorMessage
                      className="danger"
                      name="zip"
                      component="div"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      citta:
                    </label>

                    <Field
                      type="text"
                      placeholder="city"
                      className="form-control"
                      id="city"
                      name="city"
                    />
                    <ErrorMessage
                      className="danger"
                      name="city"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      province:
                    </label>

                    <Field
                      type="text"
                      className="form-control"
                      id="province"
                      placeholder="comune"
                      name="province"
                    />
                    <ErrorMessage
                      className="danger"
                      name="province"
                      component="div"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      Fonte{" "}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder="Fonte"
                      id="referal_type"
                      name="referal_type"
                    >
                      {fonteOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="referal_type"
                        component="div"
                      />
                    </Field>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      back
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default LeadForm;
