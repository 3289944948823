import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";

export default function CustomInput({
  label = "title",
  name = "info_field",
  type = "text",
  placeholder = "",
  onEdit,
  ...rest
}) {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <label className="form-label">{label}</label>
      <Field
        type={type}
        placeholder={placeholder}
        className="form-control"
        disabled={onEdit}
        name={name}
        onChange={(e) => setFieldValue(name, e.target.value)}
        {...rest}
      />
      <ErrorMessage className="danger" name={name} component="div" />
    </>
  );
}
