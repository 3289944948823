import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import WrapperModal from "../../components/ModalWrapper";
import EventForm from "../forms/EventForm";
import { useEventQuery } from "../../queries/events";
import { useAuth } from "../../authContext";
import { CUSTOMERROLE } from "../utils";

function Calender() {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditMoal] = useState(false);
  const [editId, setEditId] = useState(null);
  const { userRoleName } = useAuth();

  const { data, isIdle, isLoading, isError, error } = useEventQuery();

  useEffect(() => {
    if (data && !isLoading && data.events) {
      let events = [];
      if (data.events.length > 0) {
        events = data.events.map((e) => {
          return {
            title: e?.name,
            start: new Date(e?.start_date).toISOString(),
            end: new Date(e?.end_date).toISOString(),
            id: e?.id,
          };
        });
      }
      setEvents(events);
    }
  }, [isLoading, data]);

  const handleCellClick = (arg) => {
    /* const newEvent = {
      title: "New Event",
      start: arg.date,
      allDay: true,
    };
    setEvents([...events, newEvent]);*/
    if (userRoleName !== CUSTOMERROLE) {
      setShowModal(true);
      setEditMoal(false);
      setEditId(null);
    }
  };

  const handleEventClick = (arg) => {
    if (arg.event.id && arg.event.id > 0) {
      setShowModal(true);
      setEditMoal(true);
      setEditId(arg.event.id);
    }
  };

  return (
    <>
      <div className="calenderForm">
        <WrapperModal
          show={showModal}
          title={!editModal ? "New Appointment" : "Update Appointment"}
          handleClose={() => {
            setShowModal(!showModal);
          }}
        >
          <EventForm
            onEdit={editModal}
            editId={editId}
            onClose={() => {
              setShowModal(false);
              setEditMoal(false);
              setEditId(null);
            }}
          />
        </WrapperModal>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={events}
          dayCellDidMount={(arg) => {
            arg.el.addEventListener("click", () => handleCellClick(arg));
          }}
          eventClick={handleEventClick}
        />
      </div>
    </>
  );
}

export default Calender;
