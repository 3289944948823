import React from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function TechInfo({ techInfo, onTechInfoChange }) {
  return (
    <Container className="custom-container">
      <h3>Posizionare i moduli fotovoltaici</h3>
      <Row>
        <Col sm={12} md={6}>
          <Form.Label>
            Inclinazione tetto :{techInfo?.inclination} degress(°)
          </Form.Label>
          <Form.Range
            value={techInfo?.inclination}
            max={45}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "inclination");
            }}
          />
        </Col>
        <Col sm={12} md={6}>
          <Form.Label>
            Adattamento moduli : {techInfo?.tilt} degress(°)
          </Form.Label>
          <Form.Range
            value={techInfo?.tilt}
            max={360}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "tilt");
            }}
          />
        </Col>
      </Row>
      <Row></Row>
      <h3>Consumo di energia</h3>
      <Row>
        <Col sm={12} md={4}>
          <Form.Label>
            Consumo annuale di energia F1 : {techInfo?.f1} kW
          </Form.Label>
          <Form.Range
            value={techInfo?.f1}
            max={10000}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "f1");
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <Form.Label>
            Consumo annuale di energia F2 : {techInfo?.f2} kW
          </Form.Label>
          <Form.Range
            value={techInfo?.f2}
            max={10000}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "f2");
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <Form.Label>
            Consumo annuale di energia F3 : {techInfo?.f3} kW
          </Form.Label>
          <Form.Range
            value={techInfo?.f3}
            max={10000}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "f3");
            }}
          />
        </Col>
        {/* <Col sm={12} md={6}>
          <Form.Label>
            Prezzo attuale dell'energia : {techInfo?.energy_price} %
          </Form.Label>
          <Form.Range
            value={techInfo?.energy_price}
            max={100}
            onChange={(event) => {
              onTechInfoChange(event.target.value, "energy_price");
            }}
          />
          </Col> */}
      </Row>

      <h3>ANALISI DI FABBISOGNO ENERGETICO</h3>
      <Row>
        <Col sm={12} md={6}>
          <Form.Label>Electric Car</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "electric_car");
            }}
            value={techInfo?.electric_car}
          >
            <option>scegli</option>
            <option value="si">si</option>
            <option value="no">no</option>
            <option value="future">future</option>
          </Form.Select>
        </Col>
        <Col sm={12} md={6}>
          <Form.Label>Col</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "col");
            }}
            value={techInfo?.col}
          >
            <option>scegli</option>
            <option value="si">si</option>
            <option value="no">no</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Label>
            Da quanti utenti è abitato l'immobile nel quale installare
            l'impianto fotovoltaico?*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "people_count");
            }}
            value={techInfo?.people_count}
          >
            <option>scegli</option>
            <option value="1-2">1-2</option>
            <option value="3-4">3-4</option>
            <option value="5-6">5-6</option>
            <option value="7-8">7-8</option>
            <option value="9-n">Piue di 9</option>
          </Form.Select>
        </Col>
        <Col sm={12} md={6}>
          <Form.Label>E' presente una pompa di calore? *</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "heat_pump");
            }}
            value={techInfo?.heat_pump}
          >
            <option>scegli</option>
            <option value="si">si</option>
            <option value="no">no</option>
            <option value="futuro">futuro</option>
          </Form.Select>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Form.Label>
            Quanti climatizzatori sono presenti nell'immobile?*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "air_conditioners_pump");
            }}
            value={techInfo?.air_conditioners_pump}
          >
            <option>scegli</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="3-n">piu di 3</option>
            <option value="futuro">futuro</option>
          </Form.Select>
        </Col>
        <Col sm={12} md={6}>
          <Form.Label>Utilizzi un piano ad induzione in cucina? *</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(event) => {
              onTechInfoChange(event.target.value, "induction_hub");
            }}
            value={techInfo?.induction_hub}
          >
            <option>scegli</option>
            <option value="si">si</option>
            <option value="no">no</option>
            <option value="futuro">futuro</option>
          </Form.Select>
        </Col>
      </Row>
    </Container>
  );
}
