import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { calculateQueryString, getFromLocalStorage } from "../utils/helpers";
import { apiUrl } from "../config/paths";

/*** Queries **********************/

const _fetcher = (baseUrl, Id = null, query = {}) => {
  const uri = Id ? `/${Id}` : "";
  let queryStr = calculateQueryString(query);
  return axios
    .get(`${baseUrl}${uri}${queryStr}`, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const usePostQuery = (
  Id = null,
  enabled = true,
  query = {},
  fetcher = _fetcher
) => {
  return useQuery({
    queryKey: ["posts", Id, query],
    queryFn: () => fetcher(apiUrl + "/api/post", Id, query),
    option: {
      refetchInterval: 100,
      enabled: enabled,
    },
  });
};

/* mutations */

const createPostMutator = (params) => {
  return axios
    .post(apiUrl + `/api/post`, params, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res);
};

export const useCreatePostMutation = (mutator = createPostMutator) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("posts"),
  });
};

export const updatePostMutation = (params) => {
  const { id, data } = params;
  return axios
    .put(apiUrl + `/api/post/${id}`, data, {
      headers: {
        Authorization: getFromLocalStorage("userToken"),
        //"Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res);
};

export const useUpdatePostMutation = (mutator = updatePostMutation) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => mutator(params),
    onSuccess: () => queryClient.invalidateQueries("posts"),
  });
};
