import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ADMINROLE, paymentStatusOptions } from "./utils";
import { useAuth } from "../authContext";
import CustomSelect from "./forms/utils/CustomSelect";

const validationSchema = Yup.object().shape({});

const Filters = ({
  type = "leads",
  onAdd = () => {},
  onFilter = () => {},
  onClear = () => {},
  rolesData = [],
  users = [],
  cats = [],
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    onFilter(values);
  };
  const { userRoleName } = useAuth();

  const options = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const statusOptions = [
    { value: 0, label: "draft" },
    { value: 1, label: "copy" },
    { value: 2, label: "complete" },
  ];

  const options2 = [
    { value: "milan", label: "milan" },
    { value: "others", label: "others" },
  ];

  const handleClear = (formik) => {
    formik.resetForm();
    onClear();
  };

  const getBtnTitle = () => {
    let val = "";
    switch (type) {
      case "leads":
        val = "Aggiungi lead";
        break;

      case "roles":
        val = "add role";
        break;
      case "plans":
        val = "add plan";
        break;

      case "groups":
        val = "add group";
        break;

      case "categories":
        val = "add category";
        break;

      case "posts":
        val = "add post";
        break;

      default:
        val = "add user";
        break;
    }
    return val;
  };

  const getTitle = () => {
    let val = "";
    switch (type) {
      case "leads":
        val = "Cerca un lead";
        break;

      case "quotes":
        val = "Cerca un preventivo";
        break;

      case "roles":
        val = "Roles";
        break;

      case "requests":
        val = "Requests";
        break;

      case "categories":
        val = "Categories";
        break;

      case "plans":
        val = "Plans";
        break;

      case "groups":
        val = "Groups";
        break;

      case "posts":
        val = "Posts";
        break;

      default:
        val = "Users";
        break;
    }
    return val;
  };

  return (
    <Container fluid className="filters">
      <h3>
        {getTitle()}{" "}
        {type !== "quotes" &&
          type !== "roles" &&
          type !== "users" &&
          type !== "requests" &&
          type !== "posts" && (
            <button onClick={() => onAdd()}>{getBtnTitle(type)}</button>
          )}
      </h3>
      <Formik
        initialValues={{
          from: "",
          to: "",
          type: "",
          address: "",
          name: "",
          comune: "",
          prov: "",
          status: "",
          statusType: "",
          role_id: -1,
          install: "",
          payment: "",
          gse: "",
          company: 0,
          userId: "",
          catId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik, values) => (
          <Form>
            <Row>
              {type === "clients" && (
                <>
                  <Col md={3} className="mb-2">
                    <Field
                      type="date"
                      id="from"
                      placeholder="Dal"
                      className="form-control"
                      name="from"
                    />
                  </Col>
                  <Col md={3} className="mb-2">
                    <Field
                      type="date"
                      id="to"
                      placeholder="AI"
                      className="form-control"
                      name="to"
                    />
                  </Col>

                  <Col md={3} className="mb-2">
                    <Field
                      as="select"
                      className="form-select"
                      placeholder="Installazione"
                      id="role_id"
                      name="role_id"
                    >
                      <option value="-1" label="Roles" />
                      {rolesData.map((option) => (
                        <option
                          key={option.value}
                          value={option.id}
                          label={option.name}
                        />
                      ))}
                    </Field>
                  </Col>

                  {/*} <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">Company </label>
                      <Field
                        type="radio"
                        value={1}
                        checked={values?.company}
                        className="form-check-input me-2"
                        name="company"
                      />
                      <label className="me-2">Si </label>
                      <Field
                        type="radio"
                        value={0}
                        checked={values?.company}
                        className="form-check-input me-2"
                        name="company"
                      />
                      <label>NO </label>
                    </div>
                      </Col> */}
                </>
              )}
              {type === "quotes" && (
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    id="statusType"
                    name="statusType"
                  >
                    <option value="" label="Status" />
                    {statusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.label}
                      />
                    ))}
                  </Field>
                </Col>
              )}
              {type === "leads" && (
                <>
                  <Col md={3} className="mb-2">
                    <Field
                      as="select"
                      className="form-select"
                      id="type"
                      name="type"
                    >
                      <option value="" label="Fonte" />
                      {options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                    </Field>
                  </Col>
                  <Col md={3} className="mb-2">
                    <Field
                      type="text"
                      id="address"
                      placeholder="Indirizzo"
                      className="form-control"
                      name="address"
                    />
                  </Col>
                </>
              )}
            </Row>
            {type === "quotes" && (
              <Row>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    placeholder="Installazione"
                    id="install"
                    name="install"
                  >
                    <option value="" label="Installazione" />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    placeholder="pagementi"
                    id="payment"
                    name="payment"
                  >
                    <option value="" label="Pagementi" />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
                <Col md={3} className="mb-2">
                  <Field
                    as="select"
                    className="form-select"
                    placeholder="gse"
                    id="gse"
                    name="gse"
                  >
                    <option value="" label="pratica GSE" />
                    {paymentStatusOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </Col>
              </Row>
            )}
            {type === "posts" && (
              <Row>
                <Col md={3} className="mb-2">
                  <Field
                    type="input"
                    id="name"
                    placeholder="search by title"
                    className="form-control"
                    name="name"
                  />
                </Col>
                {userRoleName === ADMINROLE && (
                  <Col md={3} className="mb-2">
                    <CustomSelect
                      label="Choose User"
                      name="userId"
                      labelStatus={false}
                      options={
                        users &&
                        users.map((o) => {
                          return {
                            label: o.name + " " + o.lastName,
                            value: o.id,
                          };
                        })
                      }
                    />
                  </Col>
                )}
                <Col md={3} className="mb-2">
                  <CustomSelect
                    label="Choose User"
                    name="catId"
                    labelStatus={false}
                    options={
                      cats &&
                      cats.map((o) => {
                        return {
                          label: o.name,
                          value: o.id,
                        };
                      })
                    }
                  />
                </Col>
                <Col md={3}>
                  <button type="submit" className="btn-green me-2">
                    Search
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClear(formik)}
                    className="btn-red "
                  >
                    Clear
                  </button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Filters;
