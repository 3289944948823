import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
export default function SolarGrid({ zoom, loc, coordinates, slopeEdge }) {
  let gridLines = [];
  const [tiltVal, setTiltVal] = useState(0); // 0- 45 (tilt) / 0 - 360 (orientation)
  var map;
  useEffect(() => {
    loadMap();
  });

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");

    if (mapEle) {
      // eslint-disable-next-line no-undef
      map = new google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        minZoom: 5,
        maxZoom: 21,
        disableDefaultUI: true,
      });

      map.setMapTypeId("satellite");

      map.setHeading(45);

      // eslint-disable-next-line no-undef
      var aoiPolygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: map,
      });

      // area calculation
      // eslint-disable-next-line no-undef
      var polygonArea = google.maps.geometry.spherical.computeArea(
        aoiPolygon.getPath()
      );

      drawBoundingBox(aoiPolygon);

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(map, "heading_changed", function () {
        var mapRotation = map.getHeading();
        console.log(mapRotation);
      });

      function drawBoundingBox(polygon) {
        // Get bounds of the polygon
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        polygon.getPath().forEach(function (point) {
          bounds.extend(point);
        });

        // Draw bounding box
        // eslint-disable-next-line no-undef
        const boundingBox = new google.maps.Rectangle({
          bounds: bounds,
          map: map,
          editable: false,
          draggable: false,
          fillColor: "#024",
          fillOpacity: 0.5,
          strokeWeight: 1,
        });

        // Draw grid lines inside the bounding box
        drawGridLines(bounds);
      }

      function calculateGridSize(roofArea, longSide, shortSide) {
        console.log(polygonArea, longSide, shortSide);
        const R = Math.ceil(Math.sqrt(roofArea / (longSide * shortSide)));
        const C = Math.ceil(R * (longSide / shortSide));
        return [R, C];
      }

      function drawGridLines(bounds) {
        const [rows, cols] = calculateGridSize(polygonArea, 3, 3);

        const numberOfRows = rows;
        const numberOfColumns = cols;

        const startLat = bounds.getSouthWest().lat();
        const startLng = bounds.getSouthWest().lng();
        const endLat = bounds.getNorthEast().lat();
        const endLng = bounds.getNorthEast().lng();

        const latStep = (endLat - startLat) / numberOfRows;
        const lngStep = (endLng - startLng) / numberOfColumns;

        for (let i = 1; i < numberOfRows; i++) {
          const lat = startLat + i * latStep;
          // eslint-disable-next-line no-undef
          let point0 = new google.maps.LatLng(lat, startLng);
          // eslint-disable-next-line no-undef
          let point1 = new google.maps.LatLng(lat, endLng);

          // eslint-disable-next-line no-undef
          const line = new google.maps.Polyline({
            path: [point0, point1],
            map: map,
            strokeColor: "#ffffff",
            strokeOpacity: 0.5,
            strokeWeight: 1,
            clickable: true,
          });

          // Add click event listener to change color on click
          // eslint-disable-next-line no-undef
          google.maps.event.addListener(line, "click", function () {
            line.setOptions({ strokeColor: "red" });
          });

          gridLines.push(line);
        }

        for (let j = 1; j < numberOfColumns; j++) {
          const lng = startLng + j * lngStep;
          // eslint-disable-next-line no-undef
          let point2 = new google.maps.LatLng(startLat, lng);
          // eslint-disable-next-line no-undef
          let point3 = new google.maps.LatLng(endLat, lng);

          // eslint-disable-next-line no-undef
          const line = new google.maps.Polyline({
            path: [point2, point3],
            map: map,
            strokeColor: "#ffffff",
            strokeOpacity: 0.5,
            strokeWeight: 1,
            clickable: true,
          });
          gridLines.push(line);
        }
      }
    }
  };

  return (
    <>
      {/* <button
        onClick={() => {
          let val = map.getHeading() - 90;
          map.setHeading(val);
          console.log(map.getHeading());
        }}
      >
        right rotation
      </button>
      <button
        onClick={() => {
          let val = map.getHeading() + 45;
          map.setHeading(val);
          console.log(map.getHeading());
        }}
      >
        left rotation
      </button>
      <button
        onClick={() => {
          let val = map.getTilt();
          if (val === 45) {
            map.setTilt(0);
          } else {
            map.setTilt(45);
          }
          console.log("after", map.getTilt());
        }}
      >
        tilt
      </button>
      <div id="boxInfo"></div> */}
      <div id="customMap"></div>
    </>
  );
}
