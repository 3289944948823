import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import CustomInput from "./utils/CustomInput";

import { useGroupQuery } from "../../queries/groups";
import { getFromLocalStorage } from "../../utils/helpers";

import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import CustomSelect from "./utils/CustomSelect";

const GroupForm = ({ onEdit = false, editId = null, onClose = () => {} }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();
  const { authUserId } = useAuth();

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useGroupQuery(
    editId,
    editId ? true : false
  );

  const newSchema = {
    name: Yup.string().required("Nome is required"),
    //image: Yup.string().required("Image is required"),
    startDate: Yup.string().required("start date is required"),
    endDate: Yup.string().required("end date is required"),
    status: Yup.bool().required("status is required"),
  };

  const editSchema = {
    //description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      status: values?.status && values.status ? 1 : 0,
      startDate: values.startDate,
      endDate: values.endDate,
      source: "web",
      file: file,
      user_id: authUserId,
      code: values?.code,
      type: values.type?.value,
    };

    let formData = new FormData();
    formData.append("file", file);

    // Append additional form fields
    Object.keys(finalData).forEach((key) => {
      formData.append(key, finalData[key]);
    });

    try {
      const response = await axios.post(
        onEdit
          ? apiUrl + "/api/group/groupUpdate/" + editData.id
          : apiUrl + "/api/group/groupCreate",
        finalData,
        {
          headers: {
            Authorization: getFromLocalStorage("userToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.item && response.data.item.id > 0) {
        toast.dismiss();
        onEdit ? toast.success("updated") : toast.success("created");
        queryClient.invalidateQueries("groups");
        navigate(paths.groupsPath);
      }

      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("try again later");
      }
    } finally {
      setSubmitting(false);
    }

    /*

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: formData });
    } else {
      mutation.mutate(finalData);
    }*/
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    console.log(file);
  };

  const typeOptions = () => {
    return [
      {
        label: "Private",
        value: "private",
      },
      {
        label: "Public",
        value: "public",
      },
    ];
  };

  const getInitialData = (id, type) => {
    let data = {};

    if (type === "type") {
      typeOptions().forEach((q) => {
        if (q.value === id) {
          data = {
            value: q.value,
            label: q.label,
          };
        }
      });
    }

    return data;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="EventForm">something went wrong try again later</div>
    );
  }

  return (
    <Container fluid className="EventForm" style={{ padding: "20px" }}>
      <button
        onClick={() => {
          navigate(paths.groupsPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>

      <h4 className="my-2">{onEdit ? "Update" : "Create"} Group</h4>
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              startDate: editData?.startDate ?? "",
              endDate: editData?.endDate ?? "",
              status: editData?.status && editData.status ? true : false,
              image: editData?.image,
              code: editData?.code ?? "",
              type: getInitialData(editData?.type, "type"),
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col md={3} className="mb-2">
                    <div className="d-flex">
                      <label className="me-2">Status</label>
                      <Field
                        type="checkbox"
                        id="status"
                        className="form-check-input me-2"
                        name="status"
                      />
                    </div>
                  </Col>
                  {/* <Col sm={12} md={12}>
                    <CustomSelect
                      label="Status"
                      name="status"
                      defaultValue={values.status}
                      options={
                        eventStatusList &&
                        eventStatusList?.map((option, index) => ({
                          label: option.name,
                          value: option.value,
                        }))
                      }
                    />
                    </Col> */}
                </Row>

                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label="Name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.type}
                      name="code"
                      label="Code"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomSelect
                      label="Type"
                      name="type"
                      defaultValue={values.type}
                      options={typeOptions()}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="startDate"
                      label="Start date"
                      type="date"
                      defaultValue={values.start}
                      placeholder="start date"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="endDate"
                      label="End Date"
                      type="date"
                      defaultValue={values.end}
                      placeholder="end date"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <img
                      src={
                        editData?.image && editData.image
                          ? "http://127.0.0.1:8000/storage/uploads/" +
                            editData.image
                          : ""
                      }
                      alt=""
                      className="my-2"
                      width={"250px"}
                    />
                  </Col>
                  <Col sm={12} md={12}>
                    <label className="form-label">File</label>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange}
                      className="form-control my-2"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      submit
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      cancel
                        </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default GroupForm;
