import MapMarker from "./pages/MapMarker";
import Header from "./pages/Header";
import Location from "./pages/Location";
import "./styles/App.scss";
import "./styles/Configurtor.scss";
import { useEffect, useState } from "react";
import DrawingMap from "./pages/DrawingMap";
import PolygonMap from "./pages/PolygonMap";
import SolarGrid from "./pages/SolarGrid";
import { Button } from "react-bootstrap";
import TechInfo from "./pages/TechInfo";
import CustomerInfo from "./pages/CustomerInfo";
import axios from "axios";
import ListPage from "./ListPage";
import AuthModal from "./pages/AuthModal";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function App({ showHeader = true, editId = null }) {
  const [input, setInput] = useState();
  const [addressInfo, setAddressInfo] = useState();
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [location, setLocation] = useState(); //{ lat: 45.4303378, lng: 9.3227076 }
  const [polygon, setPolygon] = useState();
  const [fixedPolygon, setFixedPolygon] = useState();
  const [zoom, setZoom] = useState(20);
  const [step, setStep] = useState(0);
  const [slopeEdge, setSlopeEdge] = useState();
  const [fixedEdge, setFixedEdge] = useState();

  const locationInfo = useLocation();

  const [token, setToken] = useState(localStorage.getItem("userToken"));

  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = editId ? editId : queryParams.get("id");

  const [initialId, setInitialId] = useState(
    idParam
      ? idParam
      : localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : null
  );

  useEffect(() => {
    console.log("ID parameter changed:", idParam);
  }, [idParam]);

  const [techInfo, setTechInfo] = useState({
    inclination: 0,
    tilt: 0,
    energy_consumption: 0,
    energy_price: 0,
    energy_increase: 0,
    people_count: 0,
    heat_pump: "",
    air_conditioners_pump: "",
    induction_hub: "",
    electric_car: "",
    col: "",
    f1: 0,
    f2: 0,
    f3: 0,
  });

  const [userInfo, setUserInfo] = useState({
    name: "",
    surname: "",
    email: "",
    mobile: "",
    address: "",
    country: "",
    city: "",
    cap: "",
    province: "",
  });

  const onChange = (data) => {
    setLocation({
      lat: data?.lat,
      lng: data?.lng,
    });
    setInput(data?.address);
    setAddressInfo(data);
  };

  // google initiation
  useEffect(() => {
    const onLoad = () => {
      // the Google Maps API has loaded
      // global services instances
      window.googleAutocomplete =
        new window.google.maps.places.AutocompleteService();
      // eslint-disable-next-line no-undef
      window.googlePlacesService = new google.maps.places.PlacesService(
        document.createElement("div")
      );
    };

    const initMap = () => {};

    window.initMap = initMap;
    // check if the google maps api has loaded
    if (window.google) {
      onLoad();
    } else {
      // if the api hasn't loaded yet, add a callback function to the window object
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geometry,drawing&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = onLoad;
      document.head.appendChild(script);
    }
  });

  // getting previous data
  useEffect(() => {
    // Function to create a row and get the ID
    const createRowAndFetchId = async () => {
      try {
        const response = await axios.post(apiUrl + "/api/cusQuote", {
          status: 0,
        });
        if (
          response.status === 201 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          localStorage.setItem("userId", response.data.quote.id);
          setInitialId(response.data.quote.id);
        } else {
          console.log(response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error creating row:", error);
        toast.error("try again later");
      }
    };

    const readRequestData = async (id) => {
      try {
        const response = await axios.get(apiUrl + "/api/cusQuote/" + id);
        if (
          response.status === 200 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          // do the initial setup
          let initialData = response.data.quote;
          updateInitialData(initialData);
        } else {
          console.log(response.status, response.statusText);
        }
      } catch (error) {
        console.error("Error creating row:", error);
        toast.error("try again later");
      }
    };

    if (!initialId) {
      createRowAndFetchId();
      //console.log("create a id for to track ");
    } else if (!location) {
      readRequestData(initialId);
    }
  }, [initialId]);

  const updateInitialData = async (data) => {
    if (data?.lat && data?.lng) {
      setLocation({
        lat: parseFloat(data?.lat),
        lng: parseFloat(data?.lng),
      });
      setStep(1);
    }
    if (data?.polygon) {
      setPolygon(data?.polygon);
      setFixedPolygon(data?.polygon);
    }
    if (data?.polygon) {
      setSlopeEdge(data?.inclination_edge);
      setFixedEdge(data?.inclination_edge);
    }
    if (data?.inclination) {
      onTechInfoChange(data?.inclination, "inclination");
    }
    if (data?.tilt) {
      onTechInfoChange(data?.tilt, "tilt");
    }
    if (data?.energy_consumption) {
      onTechInfoChange(data?.energy_consumption, "energy_consumption");
    }
    if (data?.energy_price) {
      onTechInfoChange(data?.energy_price, "energy_price");
    }
    if (data?.people_count) {
      onTechInfoChange(data?.people_count, "people_count");
    }
    if (data?.heat_pump) {
      onTechInfoChange(data?.heat_pump, "heat_pump");
    }
    if (data?.air_conditioners_pump) {
      onTechInfoChange(data?.air_conditioners_pump, "air_conditioners_pump");
    }
    if (data?.induction_hub) {
      onTechInfoChange(data?.induction_hub, "induction_hub");
    }

    if (data?.electric_car) {
      onTechInfoChange(data?.electric_car, "electric_car");
    }
    if (data?.col) {
      onTechInfoChange(data?.col, "col");
    }

    if (data?.f1) {
      onTechInfoChange(data?.f1, "f1");
    }

    if (data?.f2) {
      onTechInfoChange(data?.f2, "f2");
    }

    if (data?.f3) {
      onTechInfoChange(data?.f3, "f3");
    }

    let items = [
      "name",
      "surname",
      "email",
      "mobile",
      "address",
      "country",
      "city",
      "cap",
      "province",
    ];

    items.forEach((item) => {
      if (data[item]) {
        setUserInfo((prevTechInfo) => ({
          ...prevTechInfo,
          [item]: data[item],
        }));
      }
    });
  };

  const onDrawPolygon = async (data) => {
    let res = await updateData({ polygon: data.fixed });
    if (res.status) {
      setPolygon(data.dynamic);
      setFixedPolygon(data.fixed);
      setStep(3);
    } else {
      console.log("update failed");
    }
  };

  const onZoom = (val) => {
    setZoom(val);
  };

  const onCenterChange = (val) => {
    //setLocation(val);
  };

  const onSlopeChange = async (data) => {
    let res = await updateData({
      inclination_edge: data.fixed,
      area: data.area,
    });
    if (res.status) {
      setSlopeEdge(data.dynamic);
      setFixedEdge(data.fixed);
    } else {
      console.log("update failed");
    }
  };

  const onNext = () => {
    let val = step + 1;

    if (val === 1 && location && location.lat && location.lng) {
      setStep(val);
    }

    if (val === 2 && location && location.lat && location.lng) {
      setStep(val);
    }

    if (val === 3 && location && location.lat && location.lng && polygon) {
      setStep(val);
    }

    if (
      val === 4 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge
    ) {
      setStep(val);
    }
    if (
      val === 5 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge
    ) {
      setStep(val);
    }

    if (
      val === 6 &&
      location &&
      location.lat &&
      location.lng &&
      polygon &&
      slopeEdge &&
      techInfo
    ) {
      setStep(val);
    }
  };

  const onPrev = () => {
    let val = step - 1;

    if (step > 0) setStep(val);
  };

  // update data of form
  const updateData = async (data) => {
    let payload = data;
    if (payload) {
      try {
        const response = await axios.put(
          apiUrl + "/api/cusQuote/" + initialId,
          payload
        );
        if (
          response.status === 200 &&
          response.data &&
          response.data.quote &&
          response.data.quote.id > 0
        ) {
          return { status: true };
        } else {
          return { status: false };
        }
      } catch (error) {
        //console.error("Error creating row:", error);
        return { status: false };
      }
    }
  };

  const onMarkerChange = async (data) => {
    let res = await updateData({
      lat: data?.lat,
      lng: data?.lng,
    });
    if (res.status) {
      setLocation({
        lat: data?.lat,
        lng: data?.lng,
      });
      setZoom(data?.zoom);
    } else {
      console.log("update failed");
    }
  };

  const onTechInfoChange = async (val, type) => {
    setTechInfo((prevTechInfo) => ({
      ...prevTechInfo,
      [type]: val,
    }));
  };

  return (
    <div className="main-container">
      {showHeader && <Header token={token} />}

      {step === 0 && (
        <Location
          value={input}
          onSelection={async (data) => {
            if (data && data.address) {
              let res = await updateData({
                lat: data?.lat,
                lng: data?.lng,
              });
              if (res.status) {
                onChange(data);
                setStep(1);
              } else {
                console.log("update failed");
              }
            }
          }}
        />
      )}

      {step === 1 && (
        <MapMarker
          zoom={zoom}
          loc={location}
          onZoomChange={onZoom}
          onMarkerDragged={onMarkerChange}
          onCenterChanged={onCenterChange}
        />
      )}

      {step === 2 && (
        <DrawingMap
          zoom={zoom}
          loc={location}
          onDraw={onDrawPolygon}
          coordinates={polygon}
        />
      )}
      {step === 3 && (
        <PolygonMap
          zoom={zoom}
          loc={location}
          coordinates={polygon}
          slopeEdge={slopeEdge}
          onChangeSlope={onSlopeChange}
        />
      )}
      {step === 4 && (
        <SolarGrid
          zoom={zoom}
          loc={location}
          coordinates={polygon}
          slopeEdge={slopeEdge}
        />
      )}
      {step === 5 && (
        <TechInfo techInfo={techInfo} onTechInfoChange={onTechInfoChange} />
      )}
      {step === 6 && (
        <CustomerInfo
          zoom={zoom}
          loc={location}
          techInfo={techInfo}
          coordinates={fixedPolygon}
          slopeEdge={fixedEdge}
          address={addressInfo}
          initialId={initialId}
          apiUrl={apiUrl}
          userInfo={userInfo}
          idParam={idParam}
        />
      )}

      <div className="btns">
        <Button
          style={{ marginRight: "10px" }}
          onClick={() => {
            onPrev();
          }}
          variant={step === 0 ? "secondary" : "success"}
        >
          previous
        </Button>
        {step < 6 && (
          <Button
            onClick={() => {
              onNext();
            }}
            variant={"success"}
          >
            next
          </Button>
        )}
      </div>
    </div>
  );
}

export default App;
