import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import { clientsColumns, isDateBetween } from "../utils";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import { useDeleteUserMutation, useUserQuery } from "../../queries/users";
import { useRoleQuery } from "../../queries/roles";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function Users() {
  const [list, setList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useUserQuery();
  const mutation = useDeleteUserMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const {
    data: roleData,
    isIdle: roleIdle,
    isLoading: roleLoading,
    isError: roleError,
  } = useRoleQuery();

  useEffect(() => {
    if (
      data &&
      !roleLoading &&
      !isLoading &&
      data.users &&
      data.users.length > 0
    ) {
      let apiList = data.users.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name + " " + (r?.lastName ? r?.lastName : ""),
          email: r?.email,
          company: r?.company ? "si" : "no",
          role: r?.role?.name,
          status: r.status === 1 ? "si" : "no",
          role_id: r?.role_id,
          plan: r?.plan?.name,
          companyInfo: r?.company,
          action: (
            <>
              {/* <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/users/edit/" + id);
                  }
                }}
              />
              <BsFillTrash3Fill
                data-id={r.id}
                fontSize={25}
                className="cursor me-2 danger"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: "Are you sure ?",
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              /> */}
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data, roleLoading]);

  useEffect(() => {
    if (roleData && !isLoading && roleData.roles) {
      setRoles(roleData.roles);
    }
  }, [roleLoading, roleData]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }

      if (parseInt(values.role_id) !== -1) {
        options = options.filter((s) => {
          return parseInt(s.role_id) === parseInt(values.role_id);
        });
      }

      options = options.filter((s) => {
        return parseInt(s.companyInfo) === parseInt(values.company);
      });
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="users"
        onAdd={() => navigate(paths.newUserPath)}
        onFilter={onSearch}
        onClear={onClear}
        rolesData={roles}
      />
      <div className="customTable">
        {isIdle || isLoading || roleLoading || roleIdle ? (
          "Please wait..."
        ) : (
          <CustomTable columns={clientsColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
