export const AUTOCLOSE_TIME_LONG = 4000;
export const AUTOCLOSE_TIME_SHORT = 2000;

export const ToasterConfig = {
  autoClose: AUTOCLOSE_TIME_LONG,
  closeButton: true,
  draggable: false,
  limit: 4,
  theme: "colored",
  hideProgressBar: true,
  position: "top-right",
};
