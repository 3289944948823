import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsFillTrash3Fill, BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import { groupColumns, isDateBetween } from "../utils";
import { useNavigate } from "react-router-dom";
import paths, { apiUrl } from "../../config/paths";
import { useDeleteGroupMutation, useGroupQuery } from "../../queries/groups";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function Groups() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useGroupQuery();

  const mutation = useDeleteGroupMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (mutation.data.data.status) {
        toast.success("deleted");
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let apiList = data.items.map((r) => {
        return {
          id: r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: r?.name,
          sd: r?.startDate,
          ed: r?.endDate,
          status: r?.status && r.status ? "yes" : "no",
          action: (
            <>
              <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/groups/edit/" + id);
                  }
                }}
              />
              <BsFillTrash3Fill
                data-id={r.id}
                fontSize={25}
                className="cursor me-2 danger"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: "Are you sure ?",
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        toast.loading("Deleting");
                        mutation.mutate({ id: id });
                      }
                    });
                  }
                }}
              />
            </>
          ),
        };
      });

      setFilteredData(apiList);
      setList(apiList);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.address !== "") {
        options = options.filter((s) => {
          let cString = s.add.toLowerCase();
          return cString.includes(values.address.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        onAdd={() => navigate(paths.newGroupPath)}
        onFilter={onSearch}
        onClear={onClear}
        type="groups"
      />
      <div className="customTable">
        {isIdle || isLoading ? (
          "Please wait..."
        ) : (
          <CustomTable columns={groupColumns} data={filteredData} />
        )}
      </div>
    </>
  );
}
