import React from "react";
import LeadForm from "../forms/LeadForm";
import { useParams } from "react-router-dom";

export default function EditLead() {
  const { id } = useParams();

  return (
    <>
      <LeadForm onEdit editId={id} />
    </>
  );
}
