import React from "react";
import { useParams } from "react-router-dom";
import CategoryForm from "../forms/CategoryForm";

export default function EditCategory() {
  const { id } = useParams();

  return (
    <>
      <CategoryForm onEdit editId={id} />
    </>
  );
}
