import React, { useEffect, useState } from "react";
import Filters from "../Filters";
import CustomTable from "../CustomTable";
import { BsPencilSquare } from "react-icons/bs";
import moment from "moment";
import {
  isDateBetween,
  leadColumns,
  paymentStatusOptions,
  quoteColumns,
} from "../utils";
import { useQuoteQuery } from "../../queries/quotes";
import { useNavigate } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import paths, { apiUrl } from "../../config/paths";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { BsCopy } from "react-icons/bs";
import { getFromLocalStorage } from "../../utils/helpers";

export default function Quotes() {
  const [list, setList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { data, isIdle, isLoading, isError, error } = useQuoteQuery();

  const getStatusClr = (val) => {
    let clr = "btn_yash_clr";
    paymentStatusOptions.forEach((ele) => {
      if (ele.value === parseInt(val)) {
        clr = "btn_" + ele.color;
      }
    });
    return clr;
  };

  useEffect(() => {
    if (data && !isLoading && data.quotes) {
      let apiData = data.quotes.map((r) => {
        return {
          id: "PV" + r?.id,
          date: moment(r?.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          ),
          name: (r.name ? r.name : "") + "" + (r?.surname ? r.surname : ""),
          add: r?.address,
          comune: r?.city,
          prov: r?.province,
          phone: r?.mobile,
          email: r?.email,
          status: r?.status,
          ftv: r?.ftv && r.ftv ? r.ftv + "kw" : "",
          bat: r?.bat && r.bat ? r.bat + "kw" : "",
          col: r?.col && r.col ? "si" : "no",
          discount: r?.discount && r.discount > 0 ? "-" + r.discount + "%" : "",
          price: r?.price,
          gse: <div className={"c_btn " + getStatusClr(r?.gse)}></div>,
          install: <div className={"c_btn " + getStatusClr(r?.install)}></div>,
          payment: <div className={"c_btn " + getStatusClr(r?.payment)}></div>,
          installInfo: r?.install,
          gseInfo: r?.gse,
          paymentInfo: r?.payment,
          action: (
            <>
              <BsPencilSquare
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    navigate("/preventivi/edit/" + id);
                  }
                }}
              />
              {/* <BsCopy
                data-id={r.id}
                fontSize={25}
                className="cursor me-2"
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    Swal.fire({
                      title: "sei sicuro di voler creare un utente ?",
                      text: "",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below 
                      if (result.isConfirmed) {
                        toast.warning("please wait..");

                        axios
                          .post(
                            apiUrl + "/api/quoteCopy",
                            { id: id },
                            {
                              headers: {
                                Authorization: getFromLocalStorage("userToken"),
                              },
                            }
                          )
                          .then((response) => {
                            if (
                              response.data &&
                              response.data.user &&
                              response.data.user.id > 0
                            ) {
                              toast.success(
                                "successfully created user from quote"
                              );
                              navigate(
                                "/clienti/edit/" + response.data.user.id
                              );
                            } else {
                              toast.error("try again later");
                            }
                          })
                          .catch((error) => {
                            // Handle errors
                            if (
                              error.response &&
                              error.response.data &&
                              error.response.data.message
                            ) {
                              toast.error(error.response.data.message);
                            } else {
                              toast.error("try again later");
                            }
                          });
                      }
                    });
                  }
                }}
              />*/}
              <BsEyeFill
                data-id={r.id}
                fontSize={25}
                className="cursor "
                onClick={(event) => {
                  let id = event.target.getAttribute("data-id");
                  if (id > 0) {
                    window.open("/?id=" + id, "_blank");
                  }
                }}
              />
            </>
          ),
        };
      });
      setList(apiData);
      setFilteredData(apiData);
    }
  }, [isLoading, data]);

  const onSearch = (values) => {
    var options = list;

    if (options && options.length > 0) {
      if (values.name !== "") {
        options = options.filter((s) => {
          let cString = s.name.toLowerCase();
          return cString.includes(values.name.toLowerCase());
        });
      }

      if (values.comune !== "") {
        options = options.filter((s) => {
          let cString = s.comune.toLowerCase();
          return cString.includes(values.comune.toLowerCase());
        });
      }

      if (values.statusType !== "") {
        options = options.filter((s) => {
          return s.status === values.statusType;
        });
      }

      if (values.prov !== "") {
        options = options.filter((s) => {
          let cString = s.prov.toLowerCase();
          return cString.includes(values.prov.toLowerCase());
        });
      }

      if (values.type !== "") {
        options = options.filter((s) => {
          return s.type === values.type;
        });
      }

      if (values.install !== "") {
        options = options.filter((s) => {
          return parseInt(s.installInfo) === parseInt(values.install);
        });
      }

      if (values.payment !== "") {
        options = options.filter((s) => {
          return parseInt(s.paymentInfo) === parseInt(values.payment);
        });
      }

      if (values.gse !== "") {
        options = options.filter((s) => {
          return parseInt(s.gseInfo) === parseInt(values.gse);
        });
      }

      if (values.from !== "" && values.to !== "") {
        options = options.filter((s) => {
          let date = moment(s.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          return isDateBetween(values.from, values.to, date);
        });
      }
    }

    console.log(values);

    setFilteredData(options);
  };

  const onClear = () => {
    setFilteredData(list);
  };

  return (
    <>
      <Filters
        type="quotes"
        onAdd={() => setShowModal(true)}
        onFilter={onSearch}
        onClear={onClear}
      />

      <div className="customTable">
        {isIdle || isLoading ? (
          "Please wait..."
        ) : (
          <CustomTable
            legend={true}
            columns={quoteColumns}
            data={filteredData}
          />
        )}
      </div>
    </>
  );
}
