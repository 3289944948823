import React from "react";
import CategoryForm from "../forms/CategoryForm";

export default function NewCategory() {
  return (
    <>
      <CategoryForm />
    </>
  );
}
