import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useAuth } from "../authContext";
import { useUserQuery } from "../queries/users";
import Loading from "../components/Loading";
import Slider from "./Components/Slider";
import Badge from "react-bootstrap/Badge";
import { usePostsByTypeQuery } from "../queries/postStats";

export default function Dashboard() {
  const { authUserId } = useAuth();
  const [editData, setEditData] = useState();
  const [posts, setPosts] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);

  const { data, isIdle, isLoading } = useUserQuery(
    authUserId,
    authUserId ? true : false
  );

  const {
    data: postsData,
    isIdle: postsIdle,
    isLoading: postsLoading,
    isError: postsIsError,
    error: postsError,
  } = usePostsByTypeQuery("trending", true);

  useEffect(() => {
    if (data && !isLoading && data.user) {
      setEditData(data.user);
      setPosts(data.posts);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (postsData && !postsLoading && postsData.posts) {
      setTrendingPosts(postsData.posts);
    }
  }, [postsLoading, postsData]);

  if (isLoading || isIdle) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="account_info p-3">
              <div>
                <b>Welcome back:</b> <br />
                {editData?.name && editData.lastName && (
                  <Badge bg="secondary">
                    {(editData?.name ? editData?.name : " ") +
                      " " +
                      (editData?.lastName ? editData?.lastName : "")}
                  </Badge>
                )}
              </div>
              <div>
                <b>Website: </b>{" "}
                <a href={editData?.website} target="_blank" rel="noreferrer">
                  {editData?.website}{" "}
                </a>
              </div>
              <div>
                <b>Address: </b>
                <a
                  href={"https://www.google.com/maps?q=" + editData?.address}
                  target="_blank"
                  rel="noreferrer"
                >
                  {editData?.address}
                </a>
              </div>
              <div>
                <b>Plan: </b>
                {editData?.plan && (
                  <Badge bg="secondary">
                    {editData?.plan
                      ? editData?.plan?.name +
                        " ( " +
                        posts.length +
                        "/" +
                        editData.plan.limit +
                        " ) per month"
                      : ""}
                  </Badge>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <h3>Trending posts</h3>
        <Row>
          <Col>
            <div className="postsSlider">
              <Slider list={trendingPosts} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
