export const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const apiUrl = process.env.REACT_APP_API_URL;

export const dashboardPath = "/";
export const userLoginPath = "/login";
export const leadsPath = "/leads";
export const newLeadPath = "/leads/new";
export const editLeadPath = "/leads/edit/:id";
export const quotesPath = "/preventivi";
export const newQuotePath = "/preventivi/new";
export const editQuotePath = "/preventivi/edit/:id";
export const usersPath = "/users";
export const newUserPath = "/users/new";
export const editUserPath = "/users/edit/:id";
export const rolesPath = "/roles";
export const newRolePath = "/roles/new";
export const editRolePath = "/roles/edit/:id";
export const requestsPath = "/requests";
export const editRequestPath = "/requests/edit/:id";
export const mePath = "/me";
export const calenderPath = "/calender";
export const plansPath = "/plans";
export const newPlanPath = "/plans/new";
export const editPlanPath = "/plans/edit/:id";
export const groupsPath = "/groups";
export const newGroupPath = "/groups/new";
export const editGroupPath = "/groups/edit/:id";
export const catogriesPath = "/categories";
export const newCategoryPath = "/categories/new";
export const editCategoryPath = "/categories/edit/:id";
export const postsPath = "/posts";
export const newPostPath = "/posts/new";
export const editPostPath = "/posts/edit/:id";

const paths = {
  leadsPath,
  newLeadPath,
  editLeadPath,
  userLoginPath,
  quotesPath,
  newQuotePath,
  editQuotePath,
  usersPath,
  newUserPath,
  editUserPath,
  dashboardPath,
  rolesPath,
  newRolePath,
  editRolePath,
  requestsPath,
  editRequestPath,
  mePath,
  calenderPath,
  plansPath,
  editPlanPath,
  newPlanPath,
  groupsPath,
  editGroupPath,
  newGroupPath,
  catogriesPath,
  newCategoryPath,
  editCategoryPath,
  postsPath,
  newPostPath,
  editPostPath,
};

export default paths;
