import React, { useEffect } from "react";

export default function PolygonMap({
  zoom,
  loc,
  coordinates,
  onChangeSlope,
  slopeEdge,
}) {
  useEffect(() => {
    if (loc && loc.lat && loc.lng) {
      loadMap();
    }
  }, [loc]);

  const loadMap = async () => {
    const myLatLng = loc;
    const mapEle = document.getElementById("customMap");
    if (mapEle) {
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(mapEle, {
        zoom: zoom ? parseInt(zoom) : 20,
        center: myLatLng,
        disableDefaultUI: true,
      });
      map.setMapTypeId("satellite");
      map.setHeading(45);

      // adding drawing manager
      // eslint-disable-next-line no-undef
      var polygonToShow = new google.maps.Polygon({
        paths: coordinates,
        editable: false,
        draggable: false,
        fillColor: "#3d2673",
        fillOpacity: 0.35,
        strokeColor: "#3d2673",
        strokeOpacity: 0.8,
        strokeWeight: 2,
      });
      polygonToShow.setMap(map);

      // area calculation
      // eslint-disable-next-line no-undef
      var polygonArea = google.maps.geometry.spherical.computeArea(
        polygonToShow.getPath()
      );

      // adding event listener for its edge
      // Add click event listener to the displayed polygon
      var polygons = [];
      var highlightedEdge = null;
      polygons.push(polygonToShow); // Store the polygon
      var selectedPathIndex = -1;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(polygonToShow, "click", function (event) {
        // eslint-disable-next-line no-undef
        selectedPathIndex = findNearestPathIndex(event.latLng, coordinates);
        highlightSelectedEdge(selectedPathIndex, polygonToShow);
      });

      var prevIndex = null;

      if (slopeEdge) {
        // Draw a polyline for the selected edge
        // eslint-disable-next-line no-undef
        prevIndex = new google.maps.Polyline({
          path: slopeEdge,
          editable: false,
          draggable: false,
          strokeColor: "#00FF00", // Change to your desired color
          strokeOpacity: 0.8,
          strokeWeight: 4,
        });
        prevIndex.setMap(map);
      }

      function findNearestPathIndex(latLng, coordinates) {
        // Find the nearest path index based on the clicked point
        var minDistance = Number.MAX_VALUE;
        var nearestIndex = -1;

        coordinates.forEach(function (coord, index) {
          // eslint-disable-next-line no-undef
          var distance = google.maps.geometry.spherical.computeDistanceBetween(
            latLng,
            coord
          );

          if (distance < minDistance) {
            minDistance = distance;
            nearestIndex = index;
          }
        });

        return nearestIndex;
      }

      function highlightSelectedEdge(index, polygon) {
        // Remove the previous highlight
        prevIndex?.setMap(null);
        if (highlightedEdge) {
          highlightedEdge.setMap(null);
        }

        // Create a line segment for the selected edge
        var edgeCoordinates = [
          polygon.getPath().getAt(index),
          polygon.getPath().getAt((index + 1) % polygon.getPath().getLength()),
        ];

        // Draw a polyline for the selected edge
        // eslint-disable-next-line no-undef
        highlightedEdge = new google.maps.Polyline({
          path: edgeCoordinates,
          editable: false,
          draggable: false,
          strokeColor: "#00FF00", // Change to your desired color
          strokeOpacity: 0.8,
          strokeWeight: 4,
        });

        let fixedCoordinates = highlightedEdge
          .getPath()
          .getArray()
          .map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }));

        onChangeSlope({
          dynamic: edgeCoordinates,
          fixed: fixedCoordinates,
          area: polygonArea,
        });

        highlightedEdge.setMap(map);
      }
    }
  };

  return <div id="customMap"></div>;
}
