import React from "react";
import { Field } from "formik";
import Select from "react-select";

const CustomSelect = ({
  label,
  isMulti = false,
  defaultValue,
  name,
  options,
  labelStatus = true,
  ...rest
}) => (
  <div>
    {labelStatus && <label className="form-label">{label}</label>}
    <Field name={name} className="form-select">
      {({ field, form }) => (
        <Select
          {...field}
          {...rest}
          isMulti={isMulti}
          options={options}
          onChange={(selectedOption) =>
            form.setFieldValue(name, selectedOption)
          }
          isClearable={true}
          value={defaultValue}
        />
      )}
    </Field>
  </div>
);

export default CustomSelect;
