import React from "react";
import PlanForm from "../forms/PlanForm";

export default function NewPlan() {
  return (
    <>
      <PlanForm />
    </>
  );
}
