import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext";

import paths from "./config/paths";
import MenuInfo from "./admin/MenuInfo";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={paths.userLoginPath} />;
  }

  return (
    <>
      <div className="wrappper_container">
        <MenuInfo />
        <div className="wrapper_side_right">
          <div className="wrapper_side_right_inner">{children}</div>
        </div>
      </div>
    </>
  );
};

export default PrivateRoute;
