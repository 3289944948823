import React from "react";
import { ToastContainer } from "react-toastify";
import { ToasterConfig } from "./utils/lib";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./Router";
import { AuthProvider } from "./authContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, //5 minutes
      refetchOnWindowFocus: false,
      retry: (failureCount, err) => {
        const error = err;
        // TODO: log on Sentry
        console.error(error.message);

        return (
          failureCount < 2 &&
          (!error?.response?.status || error.response.status >= 500)
        );
      },
      retryDelay: (attemptIndex) =>
        Math.min(1000 * 5 * (attemptIndex + 1), 10000),
    },
  },
});

export default function Main() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
          <ToastContainer {...ToasterConfig} />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
