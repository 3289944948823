import { useState, useRef, useEffect } from "react";

export default function Location({
  name,
  value,
  onSelection,
  countryCode = "IT",
  language = "Italian",
}) {
  const [previousAddress, setPreviousAddress] = useState(value);
  const [predictions, setPredictions] = useState([]);
  const [show, setShow] = useState(true);
  const ref = useRef(null);

  const onClose = () => {
    setShow(false);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleInputChange = (e) => {
    if (e.target.value) {
      if (window.googleAutocomplete) {
        window.googleAutocomplete.getPlacePredictions(
          {
            input: e.target.value,
            componentRestrictions: { country: countryCode },
            language: language,
          },
          (results) => {
            setPredictions(results || []);
          }
        );
      }
    } else {
      setPredictions([]);
      onSelection({});
    }
  };

  const handleSelection = (p) => {
    let finalAddress = {
      lat: null,
      lng: null,
      city: null,
      address: null,
      province: null,
      region: null,
      zip: null,
      street_number: null,
      country: null,
      country_state: null,
      country_state_code: null,
    };
    if (p.place_id !== -1) {
      let request = {
        placeId: p.place_id,
      };
      window.googlePlacesService.getDetails(request, function (place, status) {
        // eslint-disable-next-line no-undef
        if (status === google.maps.places.PlacesServiceStatus.OK && !place) {
          return;
        }
        finalAddress.lat = place.geometry.location.lat();
        finalAddress.lng = place.geometry.location.lng();
        for (let i = 0; i < place.address_components.length; i++) {
          for (let j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] === "route") {
              // address
              finalAddress.address = place.name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_2"
            ) {
              // state
              finalAddress.country_state =
                place.address_components[i].long_name;
              finalAddress.country_state_code =
                place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_2"
            ) {
              // prov
              finalAddress.province = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_1"
            ) {
              // prov
              finalAddress.region = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] === "sublocality_level_1"
            ) {
              // prov
              finalAddress.province = place.address_components[i].short_name;
            } else if (
              place.address_components[i].types[j] ===
              "administrative_area_level_3"
            ) {
              // city
              finalAddress.city = place.address_components[i].short_name;
            } else if (place.address_components[i].types[j] === "locality") {
              // city : locality,postal_town
              finalAddress.city = place.address_components[i].long_name;
            } else if (place.address_components[i].types[j] === "postal_town") {
              finalAddress.city = place.address_components[i].long_name;
            } else if (place.address_components[i].types[j] === "country") {
              finalAddress.country = place.address_components[i].long_name;
              finalAddress.country_code =
                place.address_components[i].short_name;
            } else if (place.address_components[i].types[j] === "postal_code") {
              finalAddress.zip = place.address_components[i].long_name;
            } else if (
              place.address_components[i].types[j] === "street_number"
            ) {
              finalAddress.street_number =
                place.address_components[i].long_name;
            }
          }
        }
        onSelection(finalAddress);
        if (finalAddress && finalAddress.address) {
          setPreviousAddress(finalAddress.address);
        }
      });
    }
    setPredictions([]);
    onSelection({});
  };

  return (
    <div ref={ref} className="location">
      <div className="locationInner">
        <h3>Location</h3>
        <input
          type="text"
          name={name}
          defaultValue={previousAddress}
          placeholder="Inserisci Indirizzo..."
          onChange={handleInputChange}
        />
        <div className="predictions">
          {show &&
            predictions.map((prediction) => (
              <div
                className="prediction"
                onClick={() => handleSelection(prediction)}
                key={prediction.place_id}
              >
                {prediction.description}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
