import React from "react";
import { useParams } from "react-router-dom";
import PlanForm from "../forms/PlanForm";

export default function EditPlan() {
  const { id } = useParams();

  return (
    <>
      <PlanForm onEdit editId={id} />
    </>
  );
}
