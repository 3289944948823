import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateQuoteMutation,
  useQuoteQuery,
  useUpdateQuoteMutation,
} from "../../queries/quotes";

import {
  abitiOptions,
  infoOptions,
  airConditionerOptions,
  paymentStatusOptions,
} from "../utils";
import Accordion from "react-bootstrap/Accordion";
import App from "../../App";

const QuoteForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const { data, isIdle, isLoading, isError, error } = useQuoteQuery(
    editId,
    editId ? true : false
  );

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateQuoteMutation)
    : (mutationHook = useCreateQuoteMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Nome is required"),
    surname: Yup.string().required("Cognome is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Cellulare  is required"),
    add: Yup.string().required("Street and number is required"),
    cap: Yup.number().required("Cap is required"),
    country: Yup.string().required("country is required"),
    city: Yup.string().required("city is required"),
    province: Yup.string().required("province is required"),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.quote);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.quote &&
        mutation.data.data.quote.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        navigate(paths.quotesPath);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      mobile: values.mobile,
      address: values.add,
      city: values.city,
      cap: values.cap,
      country: values.country,
      province: values.province,
      induction_hub: values?.induction_hub ?? "",
      people_count: values?.people_count ?? "",
      heat_pump: values?.heat_pump ?? "",
      air_conditioners_pump: values?.air_conditioners_pump ?? "",
      tilt: values?.tilt ?? "",
      inclination: values?.inclination ?? "",
      energy_price: values?.energy_price ?? "",
      energy_consumption: values?.energy_consumption ?? "",
      source: values?.referal_type ?? "",
      ftv: values.ftv,
      bat: values.bat,
      col: values.col ? true : false,
      discount: values.discount,
      price: values.price,
      install: values.install,
      gse: values.gse,
      payment: values.payment,
    };

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const fonteOptions = [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Passaparola" },
    { value: 3, label: "Dipendente" },
    { value: 4, label: "Sito" },
  ];

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.quotesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="QuoteForm">something went wrong try again later</div>
    );
  }

  const getPanelsCount = () => {
    return Math.round(editData?.area / (1.9 * 1.2));
  };

  const getFtvVal = () => {
    let total = parseFloat(editData?.f2) + parseFloat(editData?.f3);
    return total - total * 0.2;
  };

  return (
    <Container fluid className="QuoteForm">
      <button
        onClick={() => {
          navigate(paths.quotesPath);
        }}
        className="btn btn-secondary "
      >
        back
      </button>
      <h4 className="my-2">{onEdit ? "Update" : "Create"} preventivi</h4>

      <button
        onClick={() => {
          setShowForm(!showForm);
        }}
        className={
          !showForm
            ? "btn btn-success  my-2 mapToggle"
            : "btn btn-success  my-2"
        }
      >
        {!showForm ? "show form" : "show map"}
      </button>

      {!showForm && <App showHeader={false} editId={editId} />}
      {showForm && (
        <>
          {(editData && onEdit) || !onEdit ? (
            <Formik
              initialValues={{
                name: editData?.name ?? "",
                surname: editData?.surname ?? "",
                email: editData?.email ?? "",
                mobile: editData?.mobile ?? "",
                add: editData?.address ?? "",
                referal_type: editData?.source ?? "",
                province: editData?.province ?? "",
                city: editData?.city ?? "",
                country: editData?.country ?? "",
                cap: editData?.cap ?? "",
                induction_hub: editData?.induction_hub ?? "",
                people_count: editData?.people_count ?? "",
                heat_pump: editData?.heat_pump ?? "",
                air_conditioners_pump: editData?.air_conditioners_pump ?? "",
                tilt: editData?.tilt ?? "",
                inclination: editData?.inclination ?? "",
                energy_price: editData?.energy_price ?? "",
                energy_consumption: editData?.energy_consumption ?? "",
                ftv: editData?.ftv ?? "",
                bat: editData?.bat ?? "",
                col: editData?.col && editData.col ? true : false,
                discount: editData?.discount ?? "",
                price: editData?.price ?? "",
                install: editData?.install ?? "",
                gse: editData?.gse ?? "",
                payment: editData?.payment ?? "",
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {(formik, values) => (
                <Form>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Customer Information</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col>
                            <label className="form-label" htmlFor="firstName">
                              First Name:
                            </label>
                            <Field
                              type="text"
                              id="name"
                              placeholder="Nome"
                              className="form-control"
                              name="name"
                            />
                            <ErrorMessage
                              className="danger"
                              name="name"
                              component="div"
                            />
                          </Col>
                          <Col>
                            <label className="form-label" htmlFor="lastName">
                              Last Name:
                            </label>

                            <Field
                              type="text"
                              id="surname"
                              placeholder="Cognome"
                              className="form-control"
                              name="surname"
                            />
                            <ErrorMessage
                              className="danger"
                              name="surname"
                              component="div"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
                            <label className="form-label" htmlFor="email">
                              Email:
                            </label>

                            <Field
                              type="text"
                              id="email"
                              placeholder="Email"
                              className="form-control"
                              name="email"
                            />
                            <ErrorMessage
                              className="danger"
                              name="email"
                              component="div"
                            />
                          </Col>
                          <Col>
                            <label className="form-label" htmlFor="mobile">
                              Cellulare:
                            </label>

                            <Field
                              type="text"
                              id="mobile"
                              placeholder="Cellulare"
                              className="form-control"
                              name="mobile"
                            />
                            <ErrorMessage
                              className="danger"
                              name="mobile"
                              component="div"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="add">
                              Indirizzo:
                            </label>

                            <Field
                              type="text"
                              placeholder="indirizzo"
                              className="form-control"
                              id="add"
                              name="add"
                            />
                            <ErrorMessage
                              className="danger"
                              name="add"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="country">
                              Country:
                            </label>

                            <Field
                              type="text"
                              id="country"
                              className="form-control"
                              placeholder="country"
                              name="country"
                            />
                            <ErrorMessage
                              className="danger"
                              name="country"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="cap">
                              Cap:
                            </label>

                            <Field
                              type="text"
                              placeholder="Cap"
                              className="form-control"
                              id="cap"
                              name="cap"
                            />
                            <ErrorMessage
                              className="danger"
                              name="cap"
                              component="div"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              City:
                            </label>

                            <Field
                              type="text"
                              placeholder="city"
                              className="form-control"
                              id="city"
                              name="city"
                            />
                            <ErrorMessage
                              className="danger"
                              name="city"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="province">
                              Province:
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              id="province"
                              placeholder="comune"
                              name="province"
                            />
                            <ErrorMessage
                              className="danger"
                              name="province"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              Fonte:
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Fonte"
                              id="referal_type"
                              name="referal_type"
                            >
                              <option value="">Fonte</option>
                              {fonteOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.label}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="referal_type"
                                component="div"
                              />
                            </Field>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Posizionare i moduli fotovoltaici
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="inclination">
                              Inclinazione tetto : degress(°)
                            </label>
                            <Field
                              type="text"
                              id="inclination"
                              className="form-control"
                              name="inclination"
                              max={45}
                            />
                            <ErrorMessage
                              className="danger"
                              name="inclination"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="city">
                              Adattamento moduli : degress(°)
                            </label>
                            <Field
                              type="text"
                              id="tilt"
                              className="form-control"
                              name="tilt"
                              max={360}
                            />
                            <ErrorMessage
                              className="danger"
                              name="tilt"
                              component="div"
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Consumo di energia</Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <label
                              className="form-label"
                              htmlFor="energy_consumption"
                            >
                              Consumo annuale di energia : ct/kWh
                            </label>
                            <Field
                              type="text"
                              id="energy_consumption"
                              className="form-control"
                              name="energy_consumption"
                              max={100000}
                            />
                            <ErrorMessage
                              className="danger"
                              name="energy_consumption"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <label
                              className="form-label"
                              htmlFor="energy_price"
                            >
                              Prezzo attuale dell'energia : %
                            </label>
                            <Field
                              type="text"
                              id="energy_price"
                              className="form-control"
                              name="energy_price"
                              max={360}
                            />
                            <ErrorMessage
                              className="danger"
                              name="energy_price"
                              component="div"
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        ANALISI DI FABBISOGNO ENERGETICO
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="city">
                              Da quanti utenti è abitato l'immobile nel quale
                              installare l'impianto fotovoltaico?*
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Scegli"
                              id="people_count"
                              name="people_count"
                            >
                              <option value="">Scegli</option>

                              {abitiOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="people_count"
                                component="div"
                              />
                            </Field>
                          </Col>
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="city">
                              E' presente una pompa di calore? *
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Fonte"
                              id="heat_pump"
                              name="heat_pump"
                            >
                              <option value="">Scegli</option>
                              {infoOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="heat_pump"
                                component="div"
                              />
                            </Field>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="city">
                              Quanti climatizzatori sono presenti
                              nell'immobile?*
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Fonte"
                              id="air_conditioners_pump"
                              name="air_conditioners_pump"
                            >
                              <option value="">Scegli</option>

                              {airConditionerOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="air_conditioners_pump"
                                component="div"
                              />
                            </Field>
                          </Col>
                          <Col sm={12} md={6}>
                            <label className="form-label" htmlFor="city">
                              Utilizzi un piano ad induzione in cucina? *
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder="Fonte"
                              id="induction_hub"
                              name="induction_hub"
                            >
                              <option value="">Scegli</option>

                              {infoOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="induction_hub"
                                component="div"
                              />
                            </Field>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Estimation</Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              Installazione
                            </label>
                            <Field
                              as="select"
                              className="form-select"
                              placeholder=""
                              id="install"
                              name="install"
                            >
                              <option value="">Scegli</option>

                              {paymentStatusOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="install"
                                component="div"
                              />
                            </Field>
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              Pagementi
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder=""
                              id="payment"
                              name="payment"
                            >
                              <option value="">Scegli</option>
                              {paymentStatusOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="payment"
                                component="div"
                              />
                            </Field>
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              GSE
                            </label>

                            <Field
                              as="select"
                              className="form-select"
                              placeholder=""
                              id="gse"
                              name="gse"
                            >
                              <option value="">Scegli</option>
                              {paymentStatusOptions.map((option, index) => (
                                <option
                                  key={index}
                                  value={option.value}
                                  label={option.name}
                                />
                              ))}
                              <ErrorMessage
                                className="danger"
                                name="gse"
                                component="div"
                              />
                            </Field>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              FTV
                            </label>
                            <Field
                              type="text"
                              id="ftv"
                              placeholder="ftv"
                              className="form-control"
                              name="ftv"
                            />
                            <ErrorMessage
                              className="danger"
                              name="ftv"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              BAT
                            </label>
                            <Field
                              type="text"
                              id="bat"
                              placeholder="bat"
                              className="form-control"
                              name="bat"
                            />
                            <ErrorMessage
                              className="danger"
                              name="bat"
                              component="div"
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <label className="form-label" htmlFor="city">
                              Sconto
                            </label>
                            <Field
                              type="text"
                              id="discount"
                              placeholder="discount"
                              className="form-control"
                              name="discount"
                            />
                            <ErrorMessage
                              className="danger"
                              name="discount"
                              component="div"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12} md={12} className="mb-2">
                            <div className="d-flex">
                              <label className="me-2">COL</label>
                              <Field
                                type="checkbox"
                                id="col"
                                className="form-check-input me-2"
                                name="col"
                              />
                              <label className="me-2">Si </label>
                            </div>
                          </Col>
                          <Col sm={12} md={12}>
                            <label className="form-label" htmlFor="city">
                              Prezzo finale
                            </label>
                            <Field
                              type="text"
                              id="price"
                              placeholder="price"
                              className="form-control"
                              name="price"
                              disabled
                            />
                            <ErrorMessage
                              className="danger"
                              name="price"
                              component="div"
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Calculator</Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-2">
                          <Col sm={12} md={4}>
                            <h3>House Details</h3>
                            <div>Roof Area : {editData?.area} sqm</div>
                            <div>
                              Each Panel Area : {1.9 * 1.2} sqm (1.9 * 1.2)
                            </div>
                            <div>Each panel capacity : 0.425 kw </div>
                            <div>No of panels needed : {getPanelsCount()}</div>
                            <div>
                              No of kw needed : {getPanelsCount() * 0.425} kw
                            </div>
                            <div>F1 : {editData?.f1} KW/YEAR</div>
                            <div>F2 : {editData?.f2} KW/YEAR</div>
                            <div>F3 : {editData?.f3} KW/YEAR</div>
                            <div>F4 : {getFtvVal()} KW/YEAR</div>
                            <div>F4 : {getFtvVal() / 365} KW/DAY</div>
                          </Col>
                          <Col md={4}>
                            <h3>Extra Details</h3>
                            <div>Col : {editData?.col}</div>
                            <div>Electric car : {editData?.electric_car}</div>
                            <div>Induction : {editData?.induction_hub}</div>
                            <div>Heat pump : {editData?.heat_pump}</div>
                            <div>
                              Air conditioner :{editData?.air_conditioners_pump}
                            </div>
                            <div>People count :{editData?.people_count}</div>
                          </Col>
                          <Col md={4}>
                            <h3>Price Details</h3>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Row className="mt-2">
                    <Col>
                      <button
                        type="submit"
                        disabled={submittable}
                        className="btn btn-success me-2"
                      >
                        submit
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClear(formik)}
                        className="btn btn-secondary"
                      >
                        back
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </>
      )}
    </Container>
  );
};

export default QuoteForm;
