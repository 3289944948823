import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { apiUrl } from "../../config/paths";

function Slider({ list = [] }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
      {list &&
        list.map((l, index) => {
          return (
            <Carousel.Item key={index}>
              {l?.type == "image" && (
                <img src={apiUrl + "/storage/uploads/" + l?.file_name} alt="" />
              )}
              {l?.type == "video" && (
                <video controls width="100%" height="400px">
                  <source
                    src={apiUrl + "/storage/uploads/" + l?.video}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}

              <Carousel.Caption>
                <h3>{l?.title}</h3>
                <div className="stats">
                  <div>Likes: {l?.likesCount}</div>
                  <div>Shares: {l?.sharesCount}</div>
                  <div>Views: {l?.viewsCount}</div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
}

export default Slider;
