import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(6).required("Password  is required"),
});

const Signup = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (values, { setSubmitting }) => {
    // let's call the api to save the request of user
    let finalValues = {
      name: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };

    try {
      // Make Axios POST request to save data
      const response = await axios.post(
        apiUrl + "/api/users/create",
        finalValues
      );
      if (
        response.status === 201 &&
        response.data &&
        response.data.user &&
        response.data.user.id > 0
      ) {
        toast.success("your profile created now you can login", {
          position: "top-right",
        });
        window.location.reload();
      } else {
        console.log(response.status, response);
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Error saving data:", error);

      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "top-right",
        });
      }

      setSubmitting(false);
    }
  };

  return (
    <Container>
      <h3>Signup</h3>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col>
              <div className="mb-3">
                <label className="form-label" htmlFor="firstName">
                  First Name:
                </label>
                <Field
                  type="text"
                  id="firstName"
                  className="form-control"
                  name="firstName"
                />
                <ErrorMessage
                  className="danger"
                  name="firstName"
                  component="div"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <label className="form-label" htmlFor="lastName">
                  Last Name:
                </label>
                <Field
                  type="text"
                  id="lastName"
                  className="form-control"
                  name="lastName"
                />
                <ErrorMessage
                  className="danger"
                  name="lastName"
                  component="div"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email Address:
                </label>
                <Field
                  type="text"
                  id="email"
                  className="form-control"
                  name="email"
                />
                <ErrorMessage className="danger" name="email" component="div" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
                <Field
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                />
                <ErrorMessage
                  className="danger"
                  name="password"
                  component="div"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button className="btn btn-secondary me-2">Cancel</button>
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};

export default Signup;
